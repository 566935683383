import axios from 'axios';
import moment from 'moment';
import {storage} from 'pointant/adapter'
import {CLOUD_FUNCTION_URL,UUID_NAMESPACE,DATE_FORMAT,REST_API_URL} from './constants'
const uuidv5 = require('uuid/v5');

const MY_NAMESPACE = uuidv5(UUID_NAMESPACE,uuidv5.URL);

axios.defaults.baseURL = CLOUD_FUNCTION_URL;

const process_campaign = rawData=>{
  let redeem = {}
  let period = {}
  if(rawData.redeem_start_date){period.start = moment(rawData.redeem_start_date,DATE_FORMAT)}
  if(rawData.redeem_end_date){period.end = moment(rawData.redeem_end_date,DATE_FORMAT)}
  let reward = undefined
  if(rawData.reward_details){
    if((rawData.reward_details.en && rawData.reward_details.en!=='')
      &&(rawData.reward_details.en && rawData.reward_details.en!=='')){
        reward = rawData.reward_details
      }
  } 
  redeem = {
    details:rawData.redeem_details,
    location:rawData.redeem_location,
    opening_hours:rawData.redeem_opening_hours,
    remarks:rawData.redeem_remarks,
    period,
    reward,
  }

  //debug images
  let storageURL =  rawData.image_url
  const id = rawData.campaign_id
  // // console.log({id,storageURL})
  // if(id==18){
  //   storageURL+='"'
  //   // console.log({msg: 'Changed',id,storageURL})
  // }

  return {
    id,
    uuid: uuidv5(""+rawData.campaign_id,MY_NAMESPACE),

    name:rawData.campaign_name,
    description:rawData.description,
    location:rawData.location,
    opening_hours:rawData.opening_hours,
    redeem,

    period:{
      start:moment(rawData.start_date,DATE_FORMAT),
      end:moment(rawData.end_date,DATE_FORMAT)
    },
    gps:{
      lat:rawData.location.latitude,
      long: rawData.location.longitude
    },
    status:rawData.status,
    image: storageURL,
    imageURL:'',
    formID: rawData.form_id,
    formURL: rawData.form_url,
    registrationDetails: rawData.registration_details,
    other: {
      useQRCodeToRedeem:rawData.useQRCodeToRedeem,
      maxRedeemTimesPerDay:rawData.maxRedeemTimesPerDay,
      maxStampPerCard:rawData.maxStampPerCard,
      card_type:rawData.card_type,
      stamp_rules:rawData.stamp_rules
    },
    website: rawData.website
  }
}
const process_campaigns = (rawData)=>{
  rawData = rawData.data
  // console.log({rawData,json: JSON.stringify(rawData)})
  let filtered = rawData.filter(campaign=>{
    const a = campaign.campaign_id>=0
    const b = campaign.status==="public"
    // const c = campaign.start_date !== undefined && campaign.end_date !== undefined
    // if(process.env.NODE_ENV === 'development'){
      
    //   // console.log({c,campaign: campaign.status,campaign_dates: {s: campaign.start_date,e: campaign.end_date}})
    //   return true
    // }
    return a && b// && c
  })
  let processed = {}
  // console.log({filtered})
  let processed_array = filtered.map(process_campaign)
  processed_array.forEach(campaign=>processed[campaign.uuid]=campaign)
  return processed
}

export const getCampaign = params=>{
  return axios.get(`${REST_API_URL}/campaigns/${params.cid}`).then(data=>{
    const processedData = process_campaign(data.data[0])
    // console.log({data,processedData})
  return processedData
}).then(data=>{
  return storage
      .getImage(data.image).then(actualURL=>{
        data.imageURL = actualURL
        return data
      })
      .catch(data=>{
        // console.warn(data)
        return data
      })
}).then(data=>{
  // console.log({data})
  return data
})
}

const campaign_info = params =>{
  return axios.get(`${REST_API_URL}/campaigns`).then(process_campaigns)
}

export const by_uuid = (campaigns,uuid)=>{
  if(!campaigns){return undefined}
  const ids = Object.keys(campaigns)
  const correct_ids = ids.filter(id=>{
    return campaigns[id].uuid===uuid
  })
  if(correct_ids.length>0){
    return campaigns[correct_ids[0]]
  }else{
    return undefined
  }
}
export const uuid_list = campaigns=>{
  if(!campaigns){return []}
  const ids = Object.keys(campaigns)
  const list = ids.map(key=>campaigns[key].uuid) 
  return list
}

export default campaign_info