import React, { Component } from "react";
// import EventRegistration from "./EventRegistration";
// import { Container } from "reactstrap";
import { connect } from "react-redux";
// import queryString from "query-string";
// import {post, get } from "pointant/adapter/eventregistration";
// import register_for_event from 'pointant/store/actions/eventregistration'
import {Redirect} from 'react-router-dom'
// import StandAloneEventRegistration from './StandAloneRegistrationPage'


//Shown if not registered
//Check if token is equal to 
class Page extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirectURL: null
    };
  }
  render() {
    const { user } = this.props;
    if (user&&!this.state.redirectURL) {
      user.getIdToken(true).then(idToken => {
        const temp = {
          cid: 20,
          uid: user.uid,
          token: idToken
        };
        const query = `?cid=${temp.cid}&uid=${temp.uid}&token=${temp.token}`
        this.setState({redirectURL: `/m/campaign_registrations${query}`})
        // return ({ auth: idToken, ...params });
      });
    }

    //check user id, user token, and campaignID exists
    //check if loading data from server
    //check if already registered
    //Else show form
    if(!this.state.redirectURL){
      return <div>Redirecting...</div>
    }else{
      this.setState({redirectURL: null})
      return <Redirect to={this.state.redirectURL}/>
    }
  }
}
//need to assume that the the page gets user and campaign at minimum
//Also provide a sign in IF not signed in (use a modal?)
//Display both signed in user + campaign details for confirmation

//For now user /event_registration?cid=20&uid=adfta&auth_token=afdgija
//5 second redirect once everything's done

/**
 * Assumptions:
 * CID, UID and TOKEN is always provided and valid
 *
 * on load:
 * first get registration data for uid and cid
 * if
 *
 *
 * Flow:
 * In app user clicks link and switches to browser, where the url is event_registration with supplied campaign id, user id, auth token and dynamic link
 * Campaign ID is required
 * IF no auth details, show a sign-in overlay
 * IF no Campaign ID? show a campaign select overlay
 *
 * App gets
 */

const mapState = (state, props) => {
  return {
    // ...props,
    user: state.auth.user
  };
};
export default connect(mapState,null)(Page);
