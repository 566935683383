import React, { Component, Fragment } from "react";
import { Card, Container } from "reactstrap";
import queryString from "query-string";
import { post, get } from "pointant/adapter/eventregistration";
import { getCampaign } from 'pointant/adapter/campaign_info'
import { RegistrationQuotaFilledMessage, UserLoginFeature, RegistrationFailed, FormContainer, 
  // InputView, ConfirmView, 
  CampaignHeader, CheckingIfAlreadyRegistered, SuccessfullyRegistered, AlreadyRegistered, LoadingFormData } from './RegistrationFormViews'
// import { formFieldData, registrationDetails } from 'pointant/tempHardCodedData'
import { connect } from 'react-redux'
import VerifyEmail from './VerifyEmailOverlay'
import SignUpOverlay from './SignUpOverlay'
import { 
  // getRegistrations, registerForAnEvent, 
  getForms } from 'pointant/store/actions/eventregistration'
import { sanitiseMLT } from "pointant/utils";

//Shown if not registered

class Page extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasCheckedForRegistrationInformation: false,
      registrationInformation: null,
      sucessfullyRegistered: false,
      campaignData: null,
    };
  }
  componentDidMount() {
    const { cid } = this.props
    this.updateStatus()

    getCampaign({ cid }).then(data => {
      this.setState({ campaignData: data, })
      return data
    }).then(data=>{
      // console.log({data})
      if(data.formID){

        this.props.getFormData({
          cid,
          // formid: data.formID
        })
      }
      return data
    })
  }
  updateStatus = () => {
    const { cid, 
      // uid, token,
       user } = this.props;
    if (!user) {
      this.timer = window.setTimeout(() => this.updateStatus(), 3000)
    } else {
      user
        .getIdToken(true)
        .then(token => {
          return get({ auth: token, uid: user.uid, cid })
        }).then(data =>
          this.setState({
            registrationInformation: data,
            hasCheckedForRegistrationInformation: true,
          })
        );
    }
  }
  render() {
    const { cid,
      //  uid, token,
        user, formData,language } = this.props;
    const {
      hasCheckedForRegistrationInformation,
      registrationInformation,
      sucessfullyRegistered,
      campaignData,
    } = this.state;

    // let errorPage = null
    if(!campaignData || !campaignData.formID){
      // console.warn({campaignData,msg:"No registrations for this campaign ID"})
      return <div className='text-center app' style={{minHeight:'100vh'}}>Page Not Found</div>
    }
    let content = null;
    // console.log({ ...this.state });
    if (!user) {
      content = <UserLoginFeature />
    } else if (sucessfullyRegistered === "success") {
      content = <SuccessfullyRegistered />
    } else if (sucessfullyRegistered === "fail") {
      content = <RegistrationFailed />
    } else if (!hasCheckedForRegistrationInformation) {
      content = <CheckingIfAlreadyRegistered />;
    } else if (registrationInformation) {
      content = <AlreadyRegistered />;
    } else if (formData.isFetching || formData.firstFetch || formData.entities.fields.length<1) {
      content = <LoadingFormData />;
    } else if (formData.entities.state==='Quota Filled') {
      content = <RegistrationQuotaFilledMessage message={formData.entities.message} />;
    } else {
      content = <FormContainer 
        registrationFormFieldData={formData.entities.fields} 
        registrationDetails={sanitiseMLT(campaignData.registrationDetails,language)} cid={cid}
        registerForEvent={(params) => {
          user
            .getIdToken(true)
            .then(token => {
              return post({ ...params, auth: token, uid: user.uid, cid })
            }).then(data => {
              // console.log({ data, msg: "POST DATA RESULT" })
              this.setState({ sucessfullyRegistered: data.result })
            }
            );
        }} />;
    }
    

    return (
      <div className="app">
        <Container className="py-2 d-flex flex-column" style={{ minHeight: '100vh' }}>

        <SignUpOverlay/>
        <VerifyEmail/>
          <Card className='m-1 p-1 h-100 flex-grow-1'>
            {campaignData &&
              <Fragment>
                <CampaignHeader campaign={campaignData}/>
                <hr />
              </Fragment>
            }
            {content}
          </Card>
        </Container>
        <p className='text-center text-muted'>Event Registration Software powered by Pointant</p>
      </div>
    );
  }
}
const PageContainer = props => {
  const { location, user, formData, getFormData} = props
  const values = queryString.parse(location.search);
  const { cid, uid, token } = values;
  const childProps = {...props ,cid, uid, token, user , formData, getFormData}
  return <Page {...childProps} />
}

//No token/uid, just login instead. Campaign ID needs => uuuid?
//get firebase auth, get full campaign list, convert each to uuid

const mapState = (state, props) => {
  return {
    ...props,
    user: state.auth.user,
    formData: state.registrationform,
    language: state.ui.language,
  }
}
const mapDispatch = (dispatch)=>{
  return {
    getFormData: params => dispatch(getForms(params)),
  }
}

export default connect(mapState,mapDispatch)(PageContainer);
