import React from 'react';
import {Route,Switch} from 'react-router-dom'
import 'pointant/styles/App.css';
import Public from './Public';
import SpecialPages from './SpecialPages'

const App = props => {
  return (
    <Switch>
      <Route path='/m' component={SpecialPages}/>
      <Route path='/s' component={SpecialPages}/>
      <Route path='/' component={Public}/>
    </Switch>
  )
};

export default App;
