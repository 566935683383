import axios from "axios";
import { REST_API_URL } from "./constants";

const API_SUB_URL = "/users";

const processForm = form => {
  // console.log(form);
  const {fields} = form
  let fieldsArray = []
  Object.values(fields).forEach(field=>{
    let processedField = field
    //check if options exists
    if(field.options){
      //convert object into array
      processedField.options = Object.values(field.options).sort((a,b)=>{
        if(a.index && b.index){
    
          return a.index-b.index
        }else{
          return 0
        }
      })
      if(!processedField.default){
        processedField.default = processedField.options[0].value
      }
    }
    fieldsArray.push({...processedField,id:field.field_id})
  })
  
  fieldsArray = fieldsArray.sort((a,b)=>{
    if(a.index && b.index){

      return a.index-b.index
    }else{
      return 0
    }
  })

  return {...form,fields:fieldsArray};
};
const processForms = dataArray =>{
  return dataArray.map(form=>processForm(form))
}

export const get_form_data = ({auth,cid,formid} = {})=>{
  let headers = {};
  if (auth) {
    headers.authorization = auth;
  }
  let url = `${REST_API_URL}/forms`;
  if(formid){
    url+=`/${formid}`
  }
  const params = {cid};
  return axios.get(url, { headers, params }).then(({ data }) => {
    const { code, message } = data;
    const { forms, result } = data;
    if (code && message) {
      // console.error({ code, message });
      return null;
    }
    if(result!=="success"){
      //firebase error
      // console.error({message: "API/user/forms function successfully executed, but encountered an error. See firebase // console for more details"})
      return null
    }
    if(forms){
      //Successful form data get
      if(forms.length<1){
        //check if there are any results
        // console.error({message: "API/user/forms function successfully executed, but encountered an error. See firebase // console for more details"})
        return null
      }else{
        const form = processForms(forms)[0]
        return form

      }
    }
    if(result==='success' && message){
      //Quota filled, contact organiser message
      return {state: 'Quota Filled', message}
    }
    //if there was a form id, should only be 1 return, assume anyways
    // if(!formid){
    //   //should not occur
    //   // console.warn({message: "API/forms all function not yet implemented"})
    //   return null
    // }
    return null
  });
}

const process = data => {
  // console.log(data);
  return data.data;
};

const register_for_event = ({ auth, type, uid, cid, params, data } = {}) => {
  let headers = {};
  if (auth) {
    headers.authorization = auth;
  }
  const url = `${REST_API_URL}${API_SUB_URL}/${uid}/registrations`;
  // console.log({msg: 'posting'})
  return axios
    .post(url, { cid, form: data }, { headers, params })
    .then(process);
};

const get_registration_information = ({ auth, uid, cid } = {}) => {
  let headers = {};
  if (auth) {
    headers.authorization = auth;
  }
  const url = `${REST_API_URL}${API_SUB_URL}/${uid}/registrations`;
  const params = {};
  // console.log({auth,uid,cid})
  return axios.get(url, { headers, params }).then(({ data }) => {
    const { code, message } = data;
    if (code && message) {
      // console.error({ code, message });
      return null;
    }
    const { registrations, result } = data;
    if(result!=="success"){
      // console.error({message: "API/user/registrations function successfully executed, but encountered an error. See firebase // console for more details"})
      return null
    }
    // console.log({ data, registrations });
    const regList = registrations.filter(dataItem => {
      return dataItem.campaign_id === cid;
    });
    // console.log({regList})
    if (regList.length > 0) {
      return regList[0];
    } else {
      return null;
    }
  });
};

export default register_for_event;

export { register_for_event as post, get_registration_information as get };
