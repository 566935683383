// import campaign_info from 'pointant/adapter/campaign_info'
// import {FETCH_CAMPAIGNS} from '../action_types'
import {storage} from 'pointant/adapter'
import {FETCH_IMAGES} from '../actionTypes'
// const setActualImageURL = ({uuid,imageURL}) => ({
//   type: SET_IMAGE_URL,
//   uuid,
//   imageURL
// })
// const setImageURLs = (imageURLs)=>({type:SET_IMAGE_URLS,imageURLs})//success
const requestImages = (campaigns)=>{
  // const campaign_array = Object.keys(campaigns).map(uuid=>{
  //   return {
  //     uuid,
  //     storageURL: campaigns[uuid].image
  //   }
  // })
  // campaign_array.forEach(({uuid,storageURL}) => {
  //   storage
  //   .getImage(storageURL)
  //   .then(imageURL => {
  //     dispatch(setActualImageURL({uuid,imageURL}))
  //   });
  // });
  const campaign_uuid_list = Object.keys(campaigns)
  let uuid_and_image_url_list = campaign_uuid_list.map(uuid=>({
    uuid,
    storageURL: campaigns[uuid].image
  }))
  // uuid_and_image_url_list.push({
  //   uuid: 'debug',
  //   storageURL: 'debug-path'
  // })

  const arrPromises = uuid_and_image_url_list.map(({uuid,storageURL})=>{
    return storage
      .getImage(storageURL)
      .then(imageURL=>({uuid,imageURL}))
      .catch(data=>{
        // console.warn(data)
        return {uuid,imageURL: null}
      })
  })
  // const arrPromises = Object.keys(campaigns).map(uuid=>{
  //   const storageURL = campaigns[uuid].image
  //   return storage
  //     .getImage(storageURL)
  //     .then(imageURL=>({uuid,imageURL}))
  //     .catch(data=>{
  //       // console.warn(data)
  //       return {error: data}
  //     })
  // })
  const payload = Promise.all(arrPromises).then(arrUUIDAndImageURLs=>{
    // dispatch(setImageURLs(arrUUIDAndImageURLs))
    return arrUUIDAndImageURLs
  })

  return {
    type: FETCH_IMAGES.ACTION,
    payload
  }
}

const shouldFetchImages = (campaigns)=>{
  if(campaigns.isFetching || campaigns.firstFetch || Object.keys(campaigns.entities).length<1){
    return false
  }
  return true
}

export const fetchUIImages = ()=>{
  return (dispatch,getState)=>{
    const campaigns = getState().campaigns
    if(shouldFetchImages(campaigns)){
      return dispatch(requestImages(campaigns.entities))
    }
  }
}

export default fetchUIImages