const createAPICallAction = ({
  api_call,
  use_auth,
  action_type,
  call_condition
} = {}) => {
  const request = (state, params) => {
    const type = action_type.ACTION;
    const { user } = state.auth;

    let payload;
    // console.log(params);
    if (use_auth) {
      // console.log({params,state})
      if(params.token){
        //token was provided, so no need to get a new one from firebase user object
        payload = api_call({...params, auth: params.token})
      }else{
        payload = user
          .getIdToken(true)
          .then(idToken => {
            // console.log({idToken})
            return ({ uid: user.uid, auth: idToken, ...params });
          })
          .then(api_call)
          // .then(data => ({ data, time: Date.now() }));
      }
    } else {
      payload = api_call(params)
      // .then(data => ({ data, time: Date.now() }));
    }
    payload = payload.then(data => {
      // console.log({msg: 'API Call Response',data})
      return { data, time: Date.now() }
    });
    return {
      type,
      payload,
      meta: {
        params
      }
    };
  };
  return params => {
    return (dispatch, getState) => {
      const state = getState();
      if (call_condition(state,params)) {
        return dispatch(request(state, params));
      }else {
        // console.warn({msg:'call condition failed',state,params,request})
      }
    };
  };
};

export default createAPICallAction;
