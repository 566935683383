import { ActionType
  // PENDING, FULFILLED, REJECTED 
} from 'redux-promise-middleware';


const create_promise_action_types = (action)=>{
  return {
    ACTION: action,
    PENDING: `${action}_${ActionType.Pending}`,
    FULFILLED: `${action}_${ActionType.Fulfilled}`,
    REJECTED: `${action}_${ActionType.Rejected}`,
  }
}

export const REGISTER_FOR_EVENT = create_promise_action_types('Register for Event')

export const SEND_VERIFICATION_EMAIL = create_promise_action_types('Send Email Verification Link')
export const FETCH_REGISTRATIONS = create_promise_action_types('Get registration information')
export const FETCH_REGISTRATION_FORM_DATA = create_promise_action_types('Get form data')
export const FETCH_CAMPAIGNS = create_promise_action_types('Fetch Details')
export const FETCH_IMAGES = create_promise_action_types('Fetch Images')
export const SET_LANG = 'SET LANGUAGE'

export const CHANGE_AUTH = 'Firebase Authentication - Authentication State Changed (Signed in or (changed user)?'
export const RELOAD_USER = 'UI Authentication - Reload User'
export const SIGN_OUT = 'Firebase Authentication - Lost User Authentication'
export const USER_SIGN_OUT = 'UI - User initiated Sign Out'
export const USER_SIGN_IN = 'UI - User initiated Sign In'

export const USER_SIGN_UP = 'UI - User sign up'
export const REDIRECTED_PAGE_LOAD = 'Page loaded from authentication redirect'
export const SET_USER_PROFILE_DATA = 'Set profile data received from auth redirect'
export const SHOW_AUTH = 'Action - requires auth'
export const HIDE_AUTH = 'Action - cancel auth'
export const USER_PASS_RESET = 'UI - Password Reset'

