import React from 'react'
import {rev_texts as text} from "pointant/languages";
import DualLang from './DualLang';


const ContactUsSection = props=>{
  return (
    <div className='text-center py-5 my-5'>

    <h2 className="mb-3"><DualLang text={text.landing_enquiry}/></h2>
    <h5><DualLang text={text.landing_enquiry_email}/> <a href='mailto:contact@animaetech.com?subject=Request%20for%20Demo'>contact@animaetech.com</a></h5>
    </div>
  )
}

export default ContactUsSection