import React from "react";
import {
  AppStoreBadge,
  GooglePlayBadge,
  PointantAppLogo,
  QRCode
} from "pointant/assets";
import DualLang from "./DualLang";
import LocalisedImage from "./LocalisedImage";
import { rev_texts as text } from "pointant/languages";

const AppleBadge = props => (
  <a href='https://itunes.apple.com/hk/app/pointant/id1265535759'>

    <LocalisedImage
      src={AppStoreBadge}
      alt={text.landing_badge_apple}
      className="appleimg"
    />
  </a>
);
const GoogleBadge = props => (
  <a href='https://play.google.com/store/apps/details?id=com.animae.stamp'>

    <LocalisedImage
      src={GooglePlayBadge}
      alt={text.landing_badge_google}
      className="googleimg"
      />
      </a>
);

const DownloadBadgeSection = props => {
  return (
    <div className="h-100 badge-section">
    <div>
      <AppleBadge />
    </div>
    <div>
      <GoogleBadge />
    </div>
    </div>
  );
};

const DownloadAppText = props => (
  <div className='pl-3 download-app-t flex-grow-1'>

  <span className="white-space-pre-line mb-0 mb-sm-3">
    <b>
      <DualLang text={text.landing_app} />
    </b>
  </span>
  </div>
);
const PointantAppLogoImage = props => (
  <div
  className="logo-section"
  >
    <img
      src={PointantAppLogo}
      className="img-fluid vertical-align"
      alt={`Pointant Logo`}
    />

  </div>
);
const QRCodeImage = props => (
  <div 
    className='qr-section'
    >
  <img src={QRCode} className="img-fluid vertical-align" alt={`QR Code`}/>
  </div>
);

const ActualSection = props => {
  const test_section = [
    { comp: PointantAppLogoImage, xs: 3, sm: 2 },
    { comp: DownloadAppText, xs: 9, sm: 5 },
    { comp: DownloadBadgeSection, xs: 6, sm: 3 },
    { comp: QRCodeImage, xs: 6, sm: 2 },
  ];
  const test_section_comp = test_section.map((section,index) => (
      <section.comp key={index} />
  ));
  return <div className="d-flex responsive-heights mx-auto" >{test_section_comp}</div>;
};

export default ActualSection;
