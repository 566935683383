import React from "react";
import {
  Navbar,
  NavbarBrand,
  NavbarToggler,
  Collapse,
  Nav,
  NavLink,
  NavItem,
} from "reactstrap";
import {
  Link,
  // Route,
  // Switch,
} from "react-router-dom";
import LanguageSelector from "./LanguageSelector";
import {
  PointantLogo as logo
} from "pointant/assets";
import DualLang from './DualLang'
import { rev_texts as text } from "pointant/languages";
import AccountsButton from "./AccountsButton";

class HeaderUIState extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false
    };
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }
  render() {
    return (
      <Header toggle={this.toggle} isOpen={this.state.isOpen} />
    );
  }
}

// const HowItWorksLink = props => {
//   const { active } = props
//   if (active) { return null }
//   return (
//     <NavLink className={'p-0 ' + (active ? 'text-dark active' : 'text-secondary')} tag={Link} to='/howitworks'>
//       <h3 className={'mb-0 '}>
//         <b>

//         </b>
//       </h3>
//     </NavLink>
//   )
// }


const Header = props => {
  const { toggle, isOpen } = props
  return (
    <Navbar className="border-bottom px-3 px-sm-5" color="muted" light expand="md">
      <NavbarBrand tag={Link} to="/">
        <img src={logo} height="64" alt="Pointant" />
      </NavbarBrand>
      <NavbarToggler onClick={toggle} />
      <Collapse isOpen={isOpen} navbar className='align-self-center'>
        <Nav className="mx-3 ml-md-auto" navbar>
          <NavItem>
            <NavLink tag={Link} to='/' >
              <DualLang text={text.nav_events}/>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink tag={Link} to='/howitworks' >
              <DualLang text={text.nav_how_it_works}/>
            </NavLink>
          </NavItem>
          <div className='ml-0 ml-sm-2'/>
          <LanguageSelector />
          <AccountsButton/>
        </Nav>
      </Collapse>
    </Navbar>
  )
}

export default HeaderUIState;
