import PointantLogo from './pointantbrand.png';
import GooglePlayBadge_EN from './googleplaybadge_en.png';
import AppStoreBadge_EN from './appstorebadge_en.svg'
import GooglePlayBadge_ZH from './googleplaybadge_zh.png';
import AppStoreBadge_ZH from './appstorebadge_zh.svg';
import PointantAppLogo from './pointantapplogo.png';
import QRCode from './qrcode.png'
import BannerImage from './banner.jpg'
import AboutUsImage1 from './TW_PromotionCard-print_Front.jpg'
import AboutUsImage2 from './TW_PromotionCard-print_Back.jpg'
import GoogleSignInButtonNormal from './btn_google_signin_light_normal_web.png'
import GoogleSignInButtonDisabled from './btn_google_signin_light_disabled_web.png'
import GoogleSignInButtonFocused from './btn_google_signin_light_focus_web.png'
import GoogleSignInButtonPressed from './btn_google_signin_light_pressed_web.png'
import FacebookLogo58 from './continueWithFacebookButton.png'
// import FacebookLogo58 from './f-ogo_RGB_HEX-58.png'

const GooglePlayBadge = {
  en: GooglePlayBadge_EN,
  zh: GooglePlayBadge_ZH
}
const AppStoreBadge = {
  en: AppStoreBadge_EN,
  zh: AppStoreBadge_ZH
}
const GoogleSignInButton = {
  normal: GoogleSignInButtonNormal,
  disabled: GoogleSignInButtonDisabled,
  focused: GoogleSignInButtonFocused,
  pressed: GoogleSignInButtonPressed,
}

export {
  PointantLogo,
  GooglePlayBadge,
  AppStoreBadge,
  PointantAppLogo,
  QRCode,
  BannerImage,
  AboutUsImage1,
  AboutUsImage2,
  GoogleSignInButton,
  FacebookLogo58
}