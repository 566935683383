import { connect } from "react-redux";
import 'moment/locale/zh-hk'

const locale_trans = {
  en: 'en',
  zh: 'zh'
}

const MultiLangDate = props => {
  return props.date;
};

const mapStateToProps = (state, props) => {
  const {date} = props
  const {language} = state.ui
  const locale = locale_trans[language]
  const thedatelocale = date.locale(locale)
  const thedate = thedatelocale.format("D/M/YYYY (ddd)")
  return {
    date: thedate
  };
};

const DualDate = connect(mapStateToProps)(MultiLangDate);

export default DualDate;
