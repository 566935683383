import React, { 
  // Fragment,
   Component } from "react";

import {
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  UncontrolledDropdown,
  // Form,
  // Button,
  // Label,
  // Input,
  // FormGroup,
  // Collapse,
} from "reactstrap";
import { connect } from "react-redux";
import { userSignOut, userSignIn, showAuthUI, userPasswordReset } from "pointant/store/actions/authentication";
// import { FacebookLogo58, GoogleSignInButton } from "pointant/assets";
import {
  GoogleSignInButton,
  FacebookSignInButton,
  PointantSignInForm
} from './SignInElements'
import DualLang from "./DualLang";

import { rev_texts as text } from "pointant/languages";

//test2
const AccountNavigationItemIfUserSignedIn = props => {
  const { user, 
    // email, 
    signOut, 
    // signIn 
  } = props;
  return (
    <UncontrolledDropdown nav inNavbar>
      <DropdownToggle nav caret>
        {false && <i className="material-icons">account_circle</i>}
        <span className="">
          {user.displayName || user.email || "Currently Logged In"}
        </span>
      </DropdownToggle>
      <DropdownMenu right style={{'top':'55px'}}>
        <DropdownItem onClick={signOut}><DualLang text={text.auth_sign_out}/></DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

// class PointantSignInElement extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       email: "",
//       password: "",
//       collapse: false,
//     };
//   }
//   onSignUp = e => {
//     const { 
//       // user, signIn,
//        signUp } = this.props;
//     signUp()
//     this.setState({ collapse: false })
//   }
//   render() {
//     const { 
//       // user, 
//       signIn, 
//       // signUp, 
//       passReset, language } = this.props;
//     const { email, password } = this.state;
//     // console.log({ state: this.state , language})
//     return (
//       <Fragment>
//         <div className="px-4 pb-1 text-center">
//           {!this.state.collapse && (
//             <Button
//               className="nav-drop-item pointant-button-colour"
//               onClick={e =>
//                 this.setState(prevState => ({ collapse: !prevState.collapse }))
//               }
//             >
//               <small>
//                 Sign In with Pointant
//                 </small>
//             </Button>
//           )}
//           <Collapse isOpen={this.state.collapse}>
//             <Form
//               className="mt-1"
//               onSubmit={e => {
//                 e.preventDefault();
//                 signIn("email", { email, password });
//               }}
//             >
//               <FormGroup className="mb-1">
//                 <Label for="signInEmail" size="sm" className=" hidden mb-0">
//                   Email
//                 </Label>
//                 <Input
//                   type="email"
//                   name="email"
//                   id="signInEmail"
//                   autoComplete="email"
//                   placeholder={""}
//                   required
//                   onChange={e => this.setState({ email: e.target.value })}
//                   bsSize="sm"
//                   value={email}
//                 />
//               </FormGroup>
//               <FormGroup className="mb-1">
//                 <Label for="signInPassword" size="sm" className=" hidden mb-0">
//                   Password
//                 </Label>
//                 <Input
//                   type="password"
//                   name="password"
//                   id="signInPassword"
//                   placeholder="Password"
//                   autoComplete={"current-password"}
//                   required
//                   onChange={e => this.setState({ password: e.target.value })}
//                   bsSize="sm"
//                   value={password}
//                 />
//               </FormGroup>
//               <Button className="mb-1 w-100 pointant-button-colour" size="sm">
//                 Sign In
//               </Button>
//             </Form>
//             <Button className="mb-1 w-100 pointant-button-colour" size="sm" onClick={this.onSignUp}>
//               Sign Up
//             </Button>
//             <Button className="w-100 pointant-button-colour" size="sm" onClick={e => passReset(email)}>
//               Forgot Password
//             </Button>
//             <hr className="my-2" />
//           </Collapse>
//         </div>
//       </Fragment>
//     );
//   }
// }
class AccountNavigationItemIfUserNotSignedIn extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      dropdownOpen: false
    };
  }

  toggle() {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  }

  render() {
    // const { signIn, signUp, passReset } = this.props;
    return (
      <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle} nav inNavbar>
        <DropdownToggle nav caret>
          {false && <i className="material-icons">account_circle</i>}
          <span className=""><DualLang text={text.auth_sign_in}/></span>
        </DropdownToggle>
        <DropdownMenu right style={{ minWidth: "250px" }}>
        
          <div
            onClick={null}
            className="w-100 nav-drop-item px-4"
          >
          <PointantSignInForm />
          </div>
<DropdownItem divider/>
          <DropdownItem
            className="w-100 nav-drop-item"
          >
            <GoogleSignInButton />
          </DropdownItem>
          <DropdownItem
            className="w-100 nav-drop-item"
          >
            <FacebookSignInButton />
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    );
  }
}

const SimpleAccountButton = props => {
  const { user, 
    // email, signOut, signIn
   } = props;
  // return <AccountNavigationItemIfUserNotSignedIn {...props}/>
  return user ? (
    <AccountNavigationItemIfUserSignedIn {...props} />
  ) : (
      <AccountNavigationItemIfUserNotSignedIn {...props} />
    );
};

const mapStateToProps = (state, props) => {
  const {
    auth: { user },
    ui: {language}
  } = state;
  // // console.log({ user, auth: state.auth });
  return {
    user,
    language,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    signOut: () => dispatch(userSignOut()),
    signIn: (signInType, params) => dispatch(userSignIn(signInType, params)),
    signUp: () => dispatch(showAuthUI()),
    passReset: (email) => dispatch(userPasswordReset(email)),
  };
};

const AccountsButtonContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SimpleAccountButton);

export default AccountsButtonContainer;
