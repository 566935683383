import { createSelector } from "reselect";

const getCampaigns = state => state.campaigns.entities;
const getUUID = (state, uuid) => {
  return uuid;
}

const getCampaignByUUID = createSelector(
  [getCampaigns, getUUID],
  (campaigns, uuid) => {
    if (!campaigns) {
      return undefined;
    }
    const ids = Object.keys(campaigns);
    const correct_ids = ids.filter(id => {
      return campaigns[id].uuid === uuid;
    });
    if (correct_ids.length > 0) {
      return campaigns[correct_ids[0]];
    } else {
      return undefined;
    }
  }
);
const makeGetCampaignByUUID = () => {
  const getCampaignByUUID = createSelector(
    [getCampaigns, getUUID],
    (campaigns, uuid) => {
      if (!campaigns) {
        return undefined;
      }
      const ids = Object.keys(campaigns);
      const correct_ids = ids.filter(id => {
        return campaigns[id].uuid === uuid;
      });
      if (correct_ids.length > 0) {
        return campaigns[correct_ids[0]];
      } else {
        return undefined;
      }
    }
  );
  return getCampaignByUUID
};

export default getCampaignByUUID
export {
  makeGetCampaignByUUID
}