import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/functions';
import 'firebase/storage';

// const realAdminConfig = {
//     apiKey: "AIzaSyCEnct8U5pmrcFvXA-kMm3LAPgw2TmaUb4",
//     authDomain: "pointant-admin.firebaseapp.com",
//     databaseURL: "https://pointant-admin.firebaseio.com",
//     projectId: "pointant-admin",
//     storageBucket: "pointant-admin.appspot.com",
//     messagingSenderId: "822465249061"
// }
const clientConfig = {
  apiKey: "AIzaSyC1Md-K-qv5OKINvylQklbITrIbVqMJP5k",
  authDomain: "Pointant-app.com",
  databaseURL: "https://stampdemo-528a3.firebaseio.com",
  projectId: "stampdemo-528a3",
  storageBucket: "stampdemo-528a3.appspot.com",
  messagingSenderId: "782100825840"
}

firebase.initializeApp(clientConfig);

const googleProvider = new firebase.auth.GoogleAuthProvider();
googleProvider.addScope('https://www.googleapis.com/auth/user.birthday.read')
const facebookProvider = new firebase.auth.FacebookAuthProvider();
facebookProvider.addScope('user_birthday');
export {
  facebookProvider,
  googleProvider,
}
// const storage = firebase.storage();//Admin storage bucket, empty

export default firebase
