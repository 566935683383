import { useLayoutEffect, useCallback } from 'react'

// export const LanguageContext = React.createContext({
//   languageCode: '',
//   currentLanguageData: {},
//   switchLanguage: ()=>{},
//   parseLanguageText: ()=>'',
//   text: {},
//   parse: ()=>'',
//   getText: ()=>''
// })

// export default LanguageContext

const storageID = 'appUILanguageCode'

export const useLanguage = ({language,setLanguage})=>{
  const switchLanguage = useCallback((newLanguageCode)=>{
    let actualLanguageCode = newLanguageCode
    // if(toISOLanguageCode[newLanguageCode]){
    //   actualLanguageCode = toISOLanguageCode[newLanguageCode]
    // }
    if(availableLanguages[actualLanguageCode]){
      setLanguage(actualLanguageCode)
      window.localStorage.setItem(storageID,actualLanguageCode)
    }else{
      console.error(`Provided bad language code ${newLanguageCode}+${actualLanguageCode}`)
    }
  },[setLanguage])
  // const context = useContext(LanguageContext)
  useLayoutEffect(()=>{
    let selectedLanguageCode = 'zh'
    const cachedLanguage = window.localStorage.getItem(storageID)
    const browserLanguage = window.navigator.language
    if(cachedLanguage){
      selectedLanguageCode = cachedLanguage
    }else if(browserLanguage){
      // convert to proper language
      selectedLanguageCode = browserLanguage
    }

    // check that language is valid
    if(availableLanguages[selectedLanguageCode]){
      //valid
    }else if(toAPILanguageCode[selectedLanguageCode]){
      selectedLanguageCode = toAPILanguageCode[selectedLanguageCode]
    }else{
      // do basic conversions
      if(selectedLanguageCode.startsWith('en')){
        selectedLanguageCode = 'en'
      }else if(selectedLanguageCode.startsWith('zh')){
        selectedLanguageCode = 'zh'
      }else{
        // console.log("No valid language, setting to default english")
        selectedLanguageCode = 'zh'
      }
    }


    // window.localStorage.getItem(storageID) || window.navigator.language
    // console.log('running')
    if(selectedLanguageCode){
      switchLanguage(selectedLanguageCode)
    }
  },[switchLanguage])

  return {
    switchLanguage
  }
}

//   const parseAPILanguageText = (apiText={})=>{
//     let apiCode = languageCode
//     if(toAPILanguageCode[languageCode]){
//       apiCode = toAPILanguageCode[languageCode]
//     }
//     if(apiText[apiCode]){
//       return apiText[apiCode]
//     }else{
//       console.error({msg: 'language code error', apiCode,languageCode,toAPILanguageCode,apiText})
//       return ''
//     }
//   }

//   const getText = key=>{
//     const currentLanguageText = languageData[languageCode]
//     const subs = key.split('.')
//     // let text = ''
//     let currentTextData = currentLanguageText
//     // console.log('getting text '+key)
//     try{
//       subs.forEach(subKey=>{
//         currentTextData = currentTextData[subKey]
//         // console.log({msg:'text ',currentTextData})
//         // // console.log('text '+currentTextData)
//         if(!currentTextData){
//           throw new Error('At: '+subKey+", text:"+JSON.stringify(currentTextData))
//         }
//       })
//       if(!currentTextData){
//         throw new Error(`At: ${'End'}, text:${JSON.stringify(currentTextData)}`)
//       }
//       // console.log({msg:'result ',currentTextData})
//       return currentTextData

//     }catch(error){
//       // console.log("Could not get text data for "+key)
//       console.error(error)
//       return ''
//     }
//   }

//   return (
//     <LanguageContext.Provider 
//       value={{
//         languageCode,
//         switchLanguage,
//         currentLanguageData: languageData[languageCode],
//         parseAPILanguageText,
//         text: languageData[languageCode],
//         parse: parseAPILanguageText,
//         getText,
//         availableLanguages
//       }}>
//         {props.children}
//       </LanguageContext.Provider>
//   )
// }
// const availableLanguages = [
//   {code: 'en', short: 'EN'},
//   {code: 'zh', short: 'ZH'},
//   // {code: 'sc', short: 'SC'}
// ]

const availableLanguages = {
  'en': true,
  'zh': true
}
// const toISOLanguageCode = {
//   'en': 'en',
//   'sc': 'zh-Hans',
//   'tc': 'zh-Hant'
// }
const toAPILanguageCode = {
  'en-GB': 'en',
  'en-US': 'en',
  'zh-Hans': 'zh',
  'zh-Hant': 'zh'
}

export default useLanguage