import React, { Component, Fragment } from "react";
import { Container } from "reactstrap";
import queryString from "query-string";
import { post, get } from "pointant/adapter/eventregistration";
import { getCampaign } from 'pointant/adapter/campaign_info'

import { RegistrationQuotaFilledMessage, 
  // UserLoginFeature, 
  RegistrationFailed, FormContainer, 
  // InputView, ConfirmView, 
  CampaignHeader, CheckingIfAlreadyRegistered, SuccessfullyRegistered, AlreadyRegistered, LoadingFormData } from './RegistrationFormViews'
// import { formFieldData, registrationDetails } from 'pointant/tempHardCodedData'
import { 
  // getRegistrations, registerForAnEvent,
   getForms } from 'pointant/store/actions/eventregistration'
import {connect} from 'react-redux'
//Shown if not registered

class Page extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasCheckedForRegistrationInformation: false,
      registrationInformation: null,
      sucessfullyRegistered: false,
      campaignData: null,
    };
  }
  componentDidMount() {
    const { cid,token } = this.props
    this.updateStatus()
    
    getCampaign({ cid }).then(data => {
      this.setState({ campaignData: data, })
      return data
    }).then(data=>{
      // console.log({data})
      if(data.formID){
        this.props.getFormData({
          // formid: data.formID,
          token,
          cid
        })
      }
      return data
    })
  }
  updateStatus = () => {
    const { cid, uid, token } = this.props;
    get({ auth: token, cid, uid }).then(data =>
      this.setState({
        registrationInformation: data,
        hasCheckedForRegistrationInformation: true,
      })
    );
  }
  render() {
    const { cid, uid, token, formData } = this.props;
    const {
      hasCheckedForRegistrationInformation,
      registrationInformation,
      sucessfullyRegistered,
      campaignData,
    } = this.state;
    let content = null;
    
    // console.log({ ...this.state });
    if (!cid || !uid || !token) {
      // console.warn("URL Params incorrect")
      content = <CheckingIfAlreadyRegistered fake={true} />
    } else if (sucessfullyRegistered==="success") {
      content = <SuccessfullyRegistered />
    } else if (sucessfullyRegistered==="fail") {
      content = <RegistrationFailed />
    } else if (!hasCheckedForRegistrationInformation) {
      content = <CheckingIfAlreadyRegistered />;
    } else if (registrationInformation) {
      content = <AlreadyRegistered />;
    } else if (formData.isFetching) {
      content = <LoadingFormData />;
    } else if (!campaignData || !campaignData.formID) {
      content = null;
    } else if (formData.entities.state==='Quota Filled') {
      content = <RegistrationQuotaFilledMessage message={formData.entities.message} />;
    } else {
      content = <FormContainer scrollOnContinue='top' 
      registrationFormFieldData={formData.entities.fields} 
      registrationDetails={(campaignData&&campaignData.registrationDetails)?campaignData.registrationDetails.en:''} 
      cid={cid} 
      registerForEvent={(params) => {
        post({ ...params, uid, cid, auth: token }).then(data => {
          // console.log({ data, msg: "POST DATA RESULT" })
          let state = "fail"
          if(data.result==='success'){
            state = 'success'
          }
          this.setState({sucessfullyRegistered: state})
        })
      }} />;
    }

    return (
      <div className="app">
        <Container className="py-2" style={{ minHeight: '100vh' }}>
          <div className='m-1 p-1'>
            {campaignData &&
              <Fragment>
                <CampaignHeader campaign={campaignData} />
                <hr />
              </Fragment>
            }
            {content}
          </div>
        </Container>
      </div>
    );
  }
}
const PageContainer = props => {
  const { location, user, formData, getFormData} = props
  const values = queryString.parse(location.search);
  const { cid, uid, token } = values;
  const childProps = {...props ,cid, uid, token, user , formData, getFormData}
  return <Page {...childProps} />
}

const mapState = (state, props) => {
  return {
    ...props,
    user: state.auth.user,
    formData: state.registrationform
  }
}
const mapDispatch = (dispatch)=>{
  return {
    getFormData: params => dispatch(getForms(params)),
  }
}

export default connect(mapState,mapDispatch)(PageContainer);
