


import React, { Fragment, Component } from "react";
import { 
  // Container, Row, Col, 
  Card, CardBody } from "reactstrap";
import DualLang from "./DualLang";
import { rev_texts as text } from "pointant/languages";
import { connect } from "react-redux";
// import { getCampaignByUUID } from "pointant/selectors";
// import DualDate from './DualDate'
// import EventRegistrationForm from "./EventRegistration";
import { getRegistrations, registerForAnEvent, getForms } from 'pointant/store/actions/eventregistration'
import { RegistrationQuotaFilledMessage, CurrentlyRegistering, FormContainer, UserLoginMessage, CheckingIfAlreadyRegistered, AlreadyRegistered, SuccessfullyRegistered, RegistrationFailed, LoadingFormData } from './RegistrationFormViews'
// import { formFieldData } from 'pointant/tempHardCodedData'
// import { ifError } from "assert";

class EventRegistrationSection extends Component {
  // constructor(props) {
  //   super(props)
  // }
  componentDidMount() {
    this.getData()
  }
  componentWillUnmount() {
    this.clearData()
  }
  clearData = () => {
    if (this.timer) {

      window.clearTimeout(this.timer)
      this.timer = null
    }
  }
  getData = () => {
    const { getFormData, getRegistrations, campaign, user } = this.props
    if (!user) {
      this.timer = window.setTimeout(() => this.getData(), 3000)
    } else {
      getRegistrations({ cid: campaign.id, uid: user.uid })
      .then(data => {
        // // console.log({ data })
        getFormData({ 
          cid: campaign.id,
          // formid: campaign.formID 
        })
      })
    }

  }
  render() {
    const { formMeta, campaign, user, registrations, result, registerForEvent, 
      // getRegistrations 
    } = this.props;
    let content
    const formFieldData = this.props.formData
    const registrationDetails = campaign.registrationDetails
    // console.log({ registrationDetails, formFieldData })
    // console.log({ user })

    

    if (!user) {
      // console.warn('User not logged in')
      content = <UserLoginMessage />
    } else if (registrations.isFetching) {
      // console.warn('Getting registration information for user')
      content = <CheckingIfAlreadyRegistered />
    } else if (registrations.entities) {
      // console.warn('User is already registered for the even')
      content = <AlreadyRegistered />
    } else if (formMeta.isFetching) {
      // console.warn('Loading form data')
      content = <LoadingFormData />
    } else if (result.isFetching) {
      // console.warn('Currently Registering user')
      content = <CurrentlyRegistering />
    } else if (result.entities) {
      // console.warn('Submitting Registration Data')
      // console.log({ result: result.entities })
      const successState = result.entities.result
      if (successState === "success") {
        // console.warn('Successfully Registered? <Insert check for no quota here?>')
        content = <SuccessfullyRegistered />
      } else if (successState === 'fail') {
        // console.warn('Failed to register')
        content = <RegistrationFailed />
      } else {
        // console.log({ msg: "this shouldn't occur", successState })
        content = <RegistrationFailed />
      }
    } else if (formMeta.entities.state==='Quota Filled') {
      content = <RegistrationQuotaFilledMessage message={formMeta.entities.message} />;
    } else {
      // console.log({formMeta,formFieldData})
      // console.warn('Showing form')
      content = <FormContainer 
        registrationDetails={registrationDetails}
        registrationFormFieldData={formFieldData}
        cid={campaign.id}
        registerForEvent={(params) => {
          registerForEvent({ ...params, cid: campaign.id, uid: user.uid })
        }} />
    }
    return (
      <Fragment>
        <div className="mb-2">
          <h3>
            <DualLang text={text.campaign_registration} />
          </h3>
        </div>
        <p className='text-muted' style={{
          "whiteSpace": "pre-wrap"
        }}>
          <DualLang text={registrationDetails} defaultText='' />
        </p>

        <Card>
          <CardBody>
            {content}
          </CardBody>
        </Card>
      </Fragment>
    );
  };
}
/** */
/**
 * Things to do:
 * First check if user is logged in, if not display a please log in message
 * show loading message for getting reg data
 * check if user has already registered for the event, if so, display message
 * else show form
 * show registration success/failure
 */

const mapState = (state, ownProps) => {
  const { campaign } = ownProps
  const { auth: { user }, eventregistration, registrationresult, 
  // ui: { language },
   registrationform } = state
  return {
    campaign,
    user,
    registrations: eventregistration,
    result: registrationresult,
    formData: registrationform.entities.fields,
    formMeta: registrationform
  };
};
const mapDispatch = dispatch => {
  return {
    registerForEvent: params => dispatch(registerForAnEvent(params)),
    getRegistrations: params => dispatch(getRegistrations(params)),
    getFormData: params => dispatch(getForms(params)),
  }
}

export default connect(mapState, mapDispatch)(EventRegistrationSection);
