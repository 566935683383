import {post , get,get_form_data} from 'pointant/adapter/eventregistration'
import {REGISTER_FOR_EVENT,FETCH_REGISTRATIONS, FETCH_REGISTRATION_FORM_DATA} from "../actionTypes";
import createAPIRequests from './api_request_generator'

//Need to update information after success

const isAuthSupplied = (state,params)=>{
  if(params.token || (state.auth && state.auth.user)){
    return true
  }
  // console.log("Failed to provide auth token in call")
  return false;
}

const shouldGet = (state,params) => {
  const meta = state.eventregistration;
  // console.log(state);

  //if it requires authentication, it should check
  // fail if params.auth not supplied AND state.auth.user not supplied
  if(!isAuthSupplied(state,params)){
    // console.log("failed should get registration test - no auth token")
    return false
  }
  if (meta.isFetching) {
    // console.log("failed should get registration test - isFetching data")
    return false;
  }
  return true;
};
const shouldPost = (state,params) => {
  const regResult = state.registrationresult;
  // console.log(state);
  
  if(!isAuthSupplied(state,params)){
    // console.log("failed should post registration test - no auth token")
    return false
  }
  if (regResult.isFetching) {
    // console.log("failed should post registration form test - isFetching data")
    return false;
  }
  return true;
};
const shouldForms = (state,params) => {
  const permissions = state.registrationform;
  // console.log(state);
  // if(!isAuthSupplied(state,params)){
  //   // console.log("failed should get form test - no auth token")
  //   return false
  // }
  if (permissions.isFetching) {
    // console.log("failed should get form test - isFetching data")
    return false;
  }
  return true;
};


const getForms = createAPIRequests({
  api_call: get_form_data,
  use_auth: false,
  action_type: FETCH_REGISTRATION_FORM_DATA, 
  call_condition: shouldForms
})
const getRegistrations = createAPIRequests({
  api_call: get,
  use_auth: true,
  action_type: FETCH_REGISTRATIONS, 
  call_condition: shouldGet
})

const registerForAnEvent = createAPIRequests({
  api_call: post,
  use_auth: true,
  action_type: REGISTER_FOR_EVENT, 
  call_condition: shouldPost
})

export default getRegistrations

export {
  registerForAnEvent,
  getRegistrations,
  getForms
}