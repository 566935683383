import { connect } from "react-redux";
import { sanitiseMLT } from "pointant/utils";

const MultiLangText = props => {
  return props.text;
};

// const showCorrectLanguage = (multiLangObject, language, defaultText='N/A') => {
//   if (multiLangObject === undefined) {
//     return defaultText;
//   }
//   return multiLangObject[language];
// };

const mapStateToProps = (state, ownProps) => {
  return {
    text: sanitiseMLT(ownProps.text, state.ui.language)
  };
};

const DualLang = connect(mapStateToProps)(MultiLangText);

export default DualLang;
