import React from "react";
import { Jumbotron, Container } from "reactstrap";
import { rev_texts as text } from "pointant/languages";
import DualLang from "./DualLang";
import { BannerImage } from "pointant/assets";

const Jumbo = props => (
  <div className="border-bottom fixed-aspect fixed-aspect-responsive">
    <div className="fixed-aspect-content">
      <Jumbotron
        fluid
        className="mb-0 h-100"
        style={{
          backgroundImage: `url('${BannerImage}')`,
          backgroundSize: "cover",
          backgroundPosition: "center center"
        }}
      >
        <Container className="h-100 d-flex ">
          <div className="my-auto text-center w-100">
            <span className="jumbo-text jumbo-text-outline mb-0">
              <b>
                <DualLang text={text.landing_jumbo} />
              </b>
            </span>
          </div>
        </Container>
      </Jumbotron>
    </div>
  </div>
);
export default Jumbo;
