import React, { Component, 
  // Fragment
 } from "react";
import { FormInput } from "./CustomFormInputs";
import {
  Form,
  Button,
  ModalBody,
  Modal,
  ModalHeader,
  ModalFooter,
  Label,
  Input,
  FormGroup
} from "reactstrap";
import { userSignUp, hideAuthUI } from "pointant/store/actions/authentication";
import { connect } from "react-redux";
import moment from 'moment'
class SignUpForm extends Component {
  constructor(props) {
    super(props);
    //Receive the setup information from props
    this.getDefaultState = () => {
      let state = {
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        validPassword: null,
        confirmPassword: "",
        birth_month: "noanswer",
        birth_year: "noanswer",
        gender: "male",
        privacy: false,
        error: ""
      };
      return state;
    };
    this.state = this.getDefaultState();
    const valid_years_start = 1930
    const valid_years_end = moment().year()
    let years_data = [
      { label: "I don't want to answer", value: "noanswer" },

    ]
    let i
    for (i = valid_years_start; i <= valid_years_end; i++) {
      years_data.push({ label: '' + i, value: '' + i })
    }
    this.yearOptions = years_data
  }
  onSubmit = e => {
    e.preventDefault();
    // console.log(this.state);

    this.setState(this.getDefaultState());
    const { email, password, first_name, last_name, birth_month, birth_year, gender } = this.state;
    const { signUp } = this.props;
    let userData = { first_name, last_name, gender }
    if(birth_month!=='noanswer'){
      userData.birth_month = birth_month
    }
    if(birth_year !== 'noanswer'){
      userData.birth_year = birth_year
    }
    signUp({ email, password, userData });
  };
  render() {
    const split = 3;
    const { show, hide, 
      // signUp, 
      className } = this.props;
    const passwordError = this.state.password.length < 6 ? "Passwords must be longer than 6 characters" : null
    const confirmPasswordError = this.state.password === this.state.confirmPassword ? null : "Passwords do not match"
    return (
      <Modal isOpen={show} toggle={hide} className={className}>
        <Form onSubmit={this.onSubmit} autoComplete='on'>
          <ModalHeader toggle={hide}>
            Sign Up for a Pointant Account
        </ModalHeader>
          <ModalBody>
            <FormInput
              id={"firstName"}
              label={"First Name"}
              split={split}
              type={"text"}
              value={this.state.first_name}
              required={true}
              placeholder={"Required"}
              name={"fname"}
              autocomplete={"given-name"}
              onChange={value => this.setState({ first_name: value })}
            />

            <FormInput
              id={"lastName"}
              label={"Last Name"}
              split={split}
              type={"text"}
              value={this.state.last_name}
              required={true}
              placeholder={"Required"}
              name={"lname"}
              autocomplete={"family-name"}
              onChange={value => this.setState({ last_name: value })}
            />
            <FormInput
              id={"email"}
              label={"Email Address"}
              split={split}
              type={"email"}
              value={this.state.email}
              required={true}
              placeholder={"Required"}
              name={"email"}
              autocomplete={"email"}
              onChange={value => this.setState({ email: value })}
            />
            <FormInput
              id={"new_password"}
              label={"Password"}
              split={split}
              type={"password"}
              value={this.state.password}
              required={true}
              placeholder={"Required (must be at least 6 characters long)"}
              pattern={".{6,}"}
              name={"password"}
              autocomplete={"new-password"}
              onChange={value => {
                this.setState({ password: value })
              }}
              error={passwordError}
            />
            <FormInput
              id={"confirm_password"}
              label={"Confirm Password"}
              split={split}
              type={"password"}
              value={this.state.confirmPassword}
              required={true}
              placeholder={"Required (must be at least 6 characters long)"}
              pattern={".{6,}"}
              name={"password"}
              autocomplete={"new-password"}
              onChange={value => {
                this.setState({ confirmPassword: value })
              }}
              error={confirmPasswordError}
            />
            <FormInput
              id={"month_of_birth"}
              label={"Month of Birth"}
              split={split}
              type={"select"}
              value={this.state.birth_month}
              options={[
                { label: "I don't want to answer", value: "noanswer" },
                { label: "January", value: 1 },
                { label: "February", value: 2 },
                { label: "March", value: 3 },
                { label: "April", value: 4 },
                { label: "May", value: 5 },
                { label: "June", value: 6 },
                { label: "July", value: 7 },
                { label: "August", value: 8 },
                { label: "September", value: 9 },
                { label: "October", value: 10 },
                { label: "November", value: 11 },
                { label: "December", value: 12 }
              ]}
              required={true}
              name={"month_of_birth"}
              onChange={value => this.setState({ birth_month: value })}
            />
            <FormInput
              id={"year_of_birth"}
              label={"Year of Birth"}
              split={split}
              type={"select"}
              value={this.state.birth_year}
              options={this.yearOptions}
              required={true}
              name={"year_of_birth"}
              onChange={value => this.setState({ birth_year: value })}
            />
            <FormInput
              id={"gender"}
              label={"Gender"}
              split={split}
              type={"select"}
              value={this.state.gender}
              options={[
                { label: "I don't want to answer", value: "noanswer" },
                { label: "Female", value: "female" },
                { label: "Male", value: "male" }
              ]}
              required={true}
              name={"gender"}
              autocomplete={"sex"}
              onChange={value => this.setState({ gender: value })}
            />

            <FormGroup check className='text-center mt-1'>
              <Label check>
                <Input
                  type="checkbox"
                  value={this.state.privacy}
                  onChange={e => this.setState({ privacy: e.target.value })}
                  required
                />{" "}
                I agree to the Pointant <a
                  onClick={(e) => {
                    e.preventDefault()
                    window.open('https://pointant-app.com/policy', '', "width=600,height=300,menu=0,location=0,toolbar=0")
                  }
                  }
                  href='https://pointant-app.com/policy'
                >Privacy Policy</a>
              </Label>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" >
              Sign Up
          </Button>{" "}
            <Button color="secondary" onClick={e => hide()}>
              Cancel
          </Button>
          </ModalFooter>
        </Form>
      </Modal>
    );
  }
}
const mapState = (state, props) => {
  // console.log({ state });
  // const { showAuth } = state.ui;
  return {
    show: state.ui.showAuth,
    className: ""
  };
};
const mapDispatch = (dispatch, getState) => {
  return {
    signUp: params => dispatch(userSignUp(params)),
    hide: () => dispatch(hideAuthUI())
  };
};

export default connect(
  mapState,
  mapDispatch
)(SignUpForm);
