import React, {Fragment} from 'react'
import DualDate from './DualDate'
const Display = props=>{
  const {period} = props
  let startValid = period.start.isValid()
  let endValid = period.end.isValid()
  let bothValid = startValid && endValid
  let eitherValid = startValid || endValid
  if(!eitherValid){
    return null
  }
  if(period.start.isSame(period.end,'day')){
    return (
      <DualDate date={period.start}/>
    )
  }else{
    return (
      <Fragment>
        {startValid&&
        <DualDate date={period.start}/>
        }
        {bothValid && ` - `}
        {endValid &&
        <DualDate date={period.end}/>
        }
      </Fragment>
    )
  }
}

export default Display