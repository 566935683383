import React, { 
  // Fragment, 
  Component } from "react";
import {withRouter} from 'react-router-dom'
import {
  // Dropdown,
  // DropdownToggle,
  // DropdownItem,
  // DropdownMenu,
  // UncontrolledDropdown,
  // Form,
  Button,
  // Label,
  Input,
  // FormGroup,
  // Collapse,
} from "reactstrap";
import { connect } from "react-redux";
import { 
  // userSignOut, 
  userSignIn, showAuthUI, userPasswordReset } from "pointant/store/actions/authentication";
import { FacebookLogo58, GoogleSignInButton, PointantLogo } from "pointant/assets";
// import { FormContainer } from "../RegistrationFormViews";
import { rev_texts as text } from "pointant/languages";
import DualLang from "components/DualLang";

const GoogleSignInElement = props => {
  const { signInFunction = null } = props;
  return (
    <div onClick={e => {
      e.preventDefault()
      signInFunction()
    }
    } className="text-center" >
      <img alt={"Sign in with Google"} src={GoogleSignInButton.normal} className="img-fluid" />
    </div>
  );
}

const GoogleSignInElementContainer = connect(null,dispatch=>{
  return {
    // signOut: () => dispatch(userSignOut()),
    signInFunction: () => dispatch(userSignIn('google', {})),
    // signUp: () => dispatch(showAuthUI()),
    // passReset: (email) => dispatch(userPasswordReset(email)),
  }
})(GoogleSignInElement)

const FacebookSignInElement = props => {
  const { signInFunction = null } = props;
  return (
    <div onClick={e => {
      e.preventDefault()
      signInFunction()
    }}
    className="text-center" >
      <img alt={"Continue with Facebook"} src={FacebookLogo58} className="img-fluid " style={{maxWidth:'200px'}}/>
    </div>
  );
};
const FacebookSignInElementContainer = connect(null,dispatch=>{
  return {
    // signOut: () => dispatch(userSignOut()),
    signInFunction: () => dispatch(userSignIn('facebook', {})),
    // signUp: () => dispatch(showAuthUI()),
    // passReset: (email) => dispatch(userPasswordReset(email)),
  }
})(FacebookSignInElement)

//Pointant Logo
//Email Input
//Password Input
//Sign In Button
//Left Sign Up link, Right Forgot Password Link
//hr Or hr



class PointantSignInFormSmall extends Component {
  constructor(props) {
    super(props)
    this.state = {
      email: '',
      password: '',
      view: 'signin',//signin or forgetpassword (signup using modal)
    }
  }
  render() {
    const { signInFunction = null, small = true , signUp, passReset, language} = this.props;
    const { email, password, 
      // view 
    } = this.state
    return (
      <div
        className="mx-auto p-1"
      >
        {!small &&
          <div className='mb-2'>
            <img alt={"Pointant"} src={PointantLogo} className="img-fluid " />
          </div>
        }
        <div className='mb-1'>
          <Input
            type={'email'}
            placeholder={text.auth_email[language]}
            value={email}
            onChange={e => this.setState({ email: e.target.value })}
            required={true}
            autoComplete={'email'}
            name={'email'}
          />
        </div>
        <div className='mb-1'>

          <Input
            type={'password'}
            placeholder={text.auth_password[language]}
            value={password}
            onChange={e => this.setState({ password: e.target.value })}
            required={true}
            autoComplete={'current-password'}
            name={'password'}
          />
        </div>
        <div className='mb-1'>

          <Button
            className='w-100 pointant-button-colour'
            onClick={e=>{
              e.preventDefault()
              signInFunction(email,password)
            }

            }
          >
            <DualLang text={text.auth_sign_in}/>
          </Button>
        </div>
        <div className='d-flex flex-row justify-content-between'>
          <div 
          // className='text-muted'
            style={{cursor: 'pointer'}}
          >
            <span onClick={e=>{e.preventDefault()
            signUp()
            }} className='text-muted'>
              <small>
                <u>
                  <DualLang text={text.auth_register}/>
              </u>
              </small>
            </span>
          </div>
          <div
            style={{cursor: 'pointer'}}
            >
            <span onClick={e=>{
              e.preventDefault()
              // console.log({email: this.state.email, pathname: this.props.pathname})
              passReset({email: this.state.email, pathname: this.props.pathname})
            }
              } className='text-muted'>
              <small>
                <u>
                  <DualLang text={text.auth_forgot_password}/>
                </u>
              </small>
            </span>
          </div>
        </div>
      </div>
    );
  }
}
const PointantSignInFormSmallContainer = withRouter(connect(
  (state,props)=>({language: state.ui.language,
    pathname: props.location.pathname}),
  dispatch=>{
  return {
    // signOut: () => dispatch(userSignOut()),
    signInFunction: (email,password) => dispatch(userSignIn('email', {email,password})),
    signUp: () => dispatch(showAuthUI()),
    passReset: ({email,pathname}) => dispatch(userPasswordReset({email,pathname})),
  }
})(PointantSignInFormSmall))


export {
  GoogleSignInElementContainer as GoogleSignInElement,
  FacebookSignInElementContainer as FacebookSignInElement,
  PointantSignInFormSmallContainer as PointantSignInFormSmall,
}