import React, { Fragment } from "react";
import { Container, Row, Col } from "reactstrap";
import DualLang from "./DualLang";
import { rev_texts as text } from "pointant/languages";
import { connect } from "react-redux";
import { getCampaignByUUID } from "pointant/selectors";
// import DualDate from './DualDate'
// import EventRegistrationForm from "./EventRegistration";
import register_for_event from 'pointant/store/actions/eventregistration'
import CampaignPageEventRegistrationSection from './CampaignPageEventRegistrationSection';
import PeriodDisplay from './PeriodDisplay'

const newLineDiv = {
  "whiteSpace": "pre-wrap"
}

const Field = props => {
  const { label } = props;
  return (
    <Row>
      <Col sm="3" styles={newLineDiv}>
        <b>
          <DualLang text={label} />
        </b>
      </Col>
      <Col className={"white-space-pre-line"}>{props.children}</Col>
    </Row>
  );
};

const AdvField = props => {
  const { label, text } = props;
  const show = text !== undefined;
  return (
    <Fragment>
      {show && (
        <Field label={label}>
          <DualLang text={text} />
        </Field>
      )}
    </Fragment>
  );
};
const AdvLink = props => {
  const { label,  url } = props;
  const { text = url} = props
  const show = text !== undefined;
  return (
    <Fragment>
      {show && (
        <Field label={label}>
          <a href={url}>
          <DualLang text={text} />
          </a>
        </Field>
      )}
    </Fragment>
  );
};

const AdvDate = props => {
  const { label, period } = props;
  const show = period!== undefined && period.start !== undefined && period.end !== undefined;
  return (
    <Fragment>
      {show && (
        <Field label={label}>
          <PeriodDisplay period={period} />
        </Field>
      )}
    </Fragment>
  );
};

const CampaignDetails = props => {
  const { campaign } = props;
  return (
    <Fragment>
      <h4 className='mb-3'>
        <DualLang text={text.campaign_details} />
      </h4>
      <AdvDate
        label={text.campaign_details_date}
        period={campaign.period}
      />
      <AdvField
        label={text.campaign_details_time}
        text={campaign.opening_hours}
      />
      <AdvField
        label={text.campaign_details_location}
        text={campaign.location}
      />
      <AdvField
        label={text.campaign_details_description}
        text={campaign.description}
      />
      {campaign.website &&
      <AdvLink
        label=''
        url={campaign.website}
      />
      }
    </Fragment>
  );
};
const CampaignRewards = props => {
  const { campaign } = props;
  if (!campaign.redeem.reward) {
    return null;
  }
  return (
    <Fragment>
      <h4 className='mb-3'>
        <DualLang text={text.campaign_rewards} />
      </h4>
      <AdvField
        label={text.campaign_rewards_reward}
        text={campaign.redeem.reward}
      />
      <AdvDate
        label={text.campaign_rewards_date}
        period={campaign.redeem.period}
      />
      <AdvField
        label={text.campaign_rewards_time}
        text={campaign.redeem.opening_hours}
      />
      <AdvField
        label={text.campaign_rewards_location}
        text={campaign.redeem.location}
      />
      <AdvField
        label={text.campaign_rewards_description}
        text={campaign.redeem.details}
      />
    </Fragment>
  );
};

const CampaignPage = props => {
  const { campaign, language, showImage,
    // registerForEvent,user 
  } = props;
  const {
    // registrationDetails,
    imageURL,name,
    // id:cid,
    // formURL,
    formID} = campaign
  return (
    <Fragment>
      {showImage &&
        <img
          src={imageURL}
          className="border-bottom d-block w-100 img-fluid"
          alt={`${name[language]}`}
        />

      }
      <Container className="py-5">
        <Row className='d-flex flex-column flex-md-row'>
          <Col className=' '>

            <div className="mb-5">
              <h3>
                <DualLang text={name} />
              </h3>
            </div>
            <div className="mb-5">
              <CampaignDetails campaign={campaign} />
            </div>
            <div className="mb-0">
              <CampaignRewards campaign={campaign} />
            </div>
          </Col>
          {
            (formID)  &&
            <Col className='' md={12}>
              <CampaignPageEventRegistrationSection
                campaign={campaign}
              />
            </Col>
          }
        </Row>
      </Container>
    </Fragment>
  );
};

const mapState = (state, ownProps) => {
  const {
    match: {
      params: { campaign_uuid }
    }
  } = ownProps;
  const campaign = getCampaignByUUID(state, campaign_uuid);
  return {
    ...ownProps,
    campaign,
    language: state.ui.language,
    showImage: state.campaigns.imagesInitialised && !state.campaigns.imagesIsFetching,
    user: state.auth.user
  };
};
const mapDispatch = dispatch=>{
  return {
    registerForEvent: params => dispatch(register_for_event(params)),
  }
}

const CampaignPageContainer = connect(mapState,mapDispatch)(CampaignPage);

export default CampaignPageContainer;
