import React from "react";
import ReactDOM from "react-dom";
import "pointant/styles/index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import 'react-app-polyfill/ie9'
// import 
// // registerServiceWorker, 
// {unregister} from "./registerServiceWorker";
import Root from "./components/Root";

import store from "pointant/store";
import { auth } from "pointant/adapter";
import { changeAuth, receiveAuthRedirectResult, setUserProfile } from "pointant/store/actions/authentication";
import * as user_profile from 'pointant/adapter/user'
import * as serviceWorker from './serviceWorker';

let init = false;

auth.state_change(user => {
  if (!init) {
    ReactDOM.render(<Root store={store} />, document.getElementById("root"));
    init = true;
  }
  auth.state_change(user => store.dispatch(changeAuth(user))); //List for authentication
});

auth.get_result().then(result => {
  const { additionalUserInfo, user, 
    // credential, operationType 
  } = result;
  // console.log({
  //   result
  // });

  if (!user) {
    // console.log({ msg: "not logged in" });
    return;
  }
  store.dispatch(receiveAuthRedirectResult(result))
  const { isNewUser, profile, providerId } = additionalUserInfo;
  store.dispatch(setUserProfile(result))
  

  const { uid, email } = user;

  // console.log({
  //   profile
  // });

  //Get relevant information from social sign in
  //If first time (ie new user) POST api/user
  //else PATCH api/user

  //Question: update platform/channel data if sign in from web?

  let pointant_profile = {
    email: email, //done
    platform: "Web"
    // channel: '',//done

    // gender: '',
    // first_name: '',
    // last_name: '',
    // birth_year: '',
    // birth_month: '',
  };
  switch (providerId) {
    case "facebook.com": {
      // console.log("signed up via facebook");
      const {
        // email,
        first_name,
        // granted_scopes, //["email","public_profile"] //array of permissions?
        // id,
        last_name,
        // name,
        // picture
        // data:
        // height: 100
        // is_silhouette: false
        // url: "https://platform-lookaside.fbsbx.com/platform/profilepic/?asid=2000605086682688&height=100&width=100&ext=1551237674&hash=AeRLRyWDMAgO5NMR"
        // width: 100
      } = profile;
      // const { data } = picture;
      // const {
        // height, //int
        // is_silhouette, //bool
        // url, //url
        // width //int
      // } = data;

      pointant_profile.channel = "Facebook";
      pointant_profile.first_name = first_name;
      pointant_profile.last_name = last_name;

      // gender: '',
      // birth_year: '',
      // birth_month: '',

      break;
    }
    case "google.com": {
      // console.log("signed up via google");
      /**
       *  email: "kawai.kenneth.chan@gmail.com"
          family_name: "Chan"
          gender: "male"
          given_name: "Kenneth"
          id: "105139383788455022266"
          link: "https://plus.google.com/105139383788455022266"
          locale: "en"
          name: "Kenneth Chan"
          picture: "https://lh5.googleusercontent.com/-XgX-nIsxM0o/AAAAAAAAAAI/AAAAAAAAAAA/AKxrwcYHd7hAoarWrz0gx2Me5YC8frQVXw/mo/photo.jpg"
          verified_email: true
       */
      const {
        // email,
        family_name,
        gender,
        given_name,
        // id,
        // link,
        // locale,
        // name,
        // picture,
        // verified_email
      } = profile;

      pointant_profile.channel = "Google";
      pointant_profile.gender = gender;
      pointant_profile.first_name = given_name;
      pointant_profile.last_name = family_name;

      // birth_year: '',
      // birth_month: '',

      break;
    }
    default:
      // console.log("logged in via voodoo");
      return
  }
  // store received profile in data?
  // store.dispatch(changeAuth(user))
  // let action = user_profile.patch
  // if(isNewUser){
  //   action = user_profile.post
  // }
  // user
  // .getIdToken(true)
  // .then(idToken => ({uid: user.uid,auth: idToken,userData: pointant_profile}))
  // .then(action)  

  if (isNewUser) {
    
      //get token
      //const auth = idToken
      //call user post with data
      const uid = user.uid
      
      // const authObject = 
      user
      .getIdToken(true)
      .then(idToken => {
        // console.log({idToken})
        return user_profile.post({uid,auth: idToken,userData: pointant_profile});
      })
    //POST API
  }else{
    //PATCH API
      // const authObject = 
      user
      .getIdToken(true)
      .then(idToken => {
        // console.log({idToken})
        return user_profile.patch({uid,auth: idToken,userData: pointant_profile});
      })
  }
});
// unregister();
// registerServiceWorker();
serviceWorker.register();
