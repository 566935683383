import { connect } from "react-redux";
import React from 'react';
const LocalisedImage = props => {
  const {src,alt,style,className} = props
  return (
    <img
      src={src}
      alt={alt}
      style={style}
      className={className}
    />
    );
};

const sanImage = (multiLangImageSource, language) => {
  if (multiLangImageSource === undefined) {
    return '';
  }
  return multiLangImageSource[language];
};
const sanAlt = (multiLangAltText, language) => {
  if (multiLangAltText === undefined) {
    return '';
  }
  return multiLangAltText[language];
};

const mapStateToProps = (state, props) => {
  return {
    ...props,
    src: sanImage(props.src, state.ui.language),
    alt: sanAlt(props.alt,state.ui.language)
  };
};

const LocalisedImageContainer = connect(mapStateToProps)(LocalisedImage);

export default LocalisedImageContainer;
