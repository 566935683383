import {SET_LANG,SHOW_AUTH,HIDE_AUTH} from '../actionTypes'
import { combineReducers } from "redux";

const language = (language='zh',action)=>{
  switch(action.type){
    case SET_LANG:
      return action.language
    default:
      return language
  }
}

const showAuth = (showAuth=false,action)=>{
  switch(action.type){
    case SHOW_AUTH:
      return true
    case HIDE_AUTH:
      return false
    default:
      return false
  }
}

// export default language
const ui = combineReducers({
  language,
  showAuth
});

export default ui;