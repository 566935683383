import Q1Ans from './Q1_ans.png';
import Q1Que from './Q1.png'
import Q2Ans from './Q2_ans.png';
import Q2Que from './Q2.png'
import TitleFAQ from './Title_FAQ.png';
import TitleRegisterActive from './Title_register_active.png'
import TitleRegisterInactive from './Title_register.png'
import TitleStampActive from './Title_stamps_active.png'
import TitleStampInactive from './Title_stamps.png'

const Q1QueDetails = {
  img: Q1Que,
  alt: 'How to register for an event'
}
const Q1AnsDetails = {
  img: Q1Ans,
  alt: 'Visit http://pointant-app.com/, sign in, select an event and then fill and submit the registration form'
}
const Q2QueDetails = {
  img: Q2Que,
  alt: 'How to collect stamps'
}
const Q2AnsDetails = {
  img: Q2Ans,
  alt: 'Scan the QR code to download the Pointant App, open the stamp collecting page and stamp the middle of the screen'
}

export {
  Q1AnsDetails as Q1Ans,
  Q1QueDetails as Q1Que,
  Q2AnsDetails as Q2Ans,
  Q2QueDetails as Q2Que,
TitleFAQ,
TitleRegisterActive,
TitleRegisterInactive,
TitleStampActive,
TitleStampInactive,
}
