import firebase, { googleProvider, facebookProvider } from "./firebase";

const auth = firebase.auth();

const sign_up = (email, password) =>
  auth.createUserWithEmailAndPassword(email, password);

const sign_in = (email, password) =>
  auth.signInWithEmailAndPassword(email, password);

const google_sign_in = () => auth.signInWithRedirect(googleProvider);
const facebook_sign_in = () => auth.signInWithRedirect(facebookProvider);

const sign_out = () => auth.signOut();

const pass_update = password => auth.currentUser.updatePassword(password);

const state_change = onChange => auth.onAuthStateChanged(onChange);

const get_user = () => auth.currentUser;

const get_result = () => auth.getRedirectResult();

const reload_user = async()=>{
  const user = await get_user()
  await user.reload()
  return get_user()
}

const defaultPointantAdminBaseURL = 'https://pointant-app.com'
var defaultActionCodeSettings = {
  url: defaultPointantAdminBaseURL,
  // iOS: {
  //   bundleId: 'com.example.ios'
  // },
  // android: {
  //   packageName: 'com.example.android',
  //   installApp: true,
  //   minimumVersion: '12'
  // },
  // handleCodeInApp: true
};

const pass_reset = ({email,actionCodeSettings,pathname}) => {
  let acs = {...defaultActionCodeSettings,...actionCodeSettings}
  if(pathname){
    acs.url = defaultPointantAdminBaseURL+pathname
  }
  // console.log({email,actionCodeSettings:acs,msg:'sending password reset with'})
  return auth.sendPasswordResetEmail(email,acs)
    .then(function() {
      // Password reset email sent.
      // console.log({msg:'reset email sent'})
    })
    .catch(function(error) {
      // Error occurred. Inspect error.code.
      // console.log({msg:'reset email not sent', error})
      throw error
    });
  
};

const send_verify_email = async ({actionCodeSettings, pathname}) => {
  let acs = {...defaultActionCodeSettings,...actionCodeSettings}
  if(pathname){
    acs.url = defaultPointantAdminBaseURL+pathname
  }
  // console.log({email: auth.currentUser.email,actionCodeSettings:acs,msg:'sending email verify with'})
  auth.useDeviceLanguage()
  if(auth.currentUser){
    return auth.currentUser.sendEmailVerification(acs);
  }else{
    console.error("Could not send verification email as there is no signed in user")
    throw new Error("Could not send verification email as there is no signed in user")
  }
}

export {
  send_verify_email,
  sign_up,
  sign_in,
  sign_out,
  pass_reset,
  pass_update,
  state_change,
  get_user,
  google_sign_in,
  facebook_sign_in,
  get_result,
  reload_user
};
// export const {

// }
