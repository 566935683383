import {FETCH_CAMPAIGNS as ACTION_TYPE,FETCH_IMAGES} from '../actionTypes'

const {PENDING,FULFILLED,REJECTED} = ACTION_TYPE


function image(campaign,imageURL){
  return {...campaign,imageURL}
}

function images(campaigns,action){
  //campaigns is an object of {uuid:campaign}
  //action.payload is a list of {uuid,imageURL}
  //need to set imageURL for each campaign
  const newCampaigns = {...campaigns}
  action.payload.forEach(({uuid,imageURL}) => {
    newCampaigns[uuid] = image(campaigns[uuid],imageURL)
  });
  return newCampaigns
}

function both(state = {
  error: '',
  isFetching: false,
  entities: {},
  firstFetch: true,
  imagesInitialised: false,
  imagesIsFetching: false
},action){
  switch (action.type){
    case PENDING:
    case FULFILLED:
    case REJECTED:
      return campaigns(state,action)
      case FETCH_IMAGES.PENDING:
        return {...state,imagesInitialised:true,imagesIsFetching:true}
    case FETCH_IMAGES.FULFILLED:
      return {...state,entities:images(state.entities,action),imagesIsFetching:false}
    case FETCH_IMAGES.REJECTED:
      return {...state,error:action.error,imagesIsFetching:false}
    default:
      return state
  }
}


function campaigns(state,action){
  switch (action.type) {
    case PENDING:
      return {
        ...state,
        isFetching: true,
        error: '',
        firstFetch: false
      }
    case FULFILLED:
      return {
        ...state,
        isFetching: false,
        lastUpdated: action.payload.time,
        entities: action.payload.data
      }
    case REJECTED:
      return {
        ...state,
        isFetching: false,
        error: action.payload
      }
    default:
      return state
  }
}

export default both