import React, {Component} from "react";
import { Route, Switch } from "react-router-dom";
import CampaignPage from "./CampaignPage";
import NotFoundPage from "./UnknownPage";
import LandingPage from "./LandingPage";
import Footer from "./Footer";
import Header from "./Header";
import PointantJumbo from "./PointantJumbo";
// import Solutions from './SolutionsPage'

import { connect } from "react-redux";
import {getCampaignUUIDs} from 'pointant/selectors'
// import AuthOverlay from './AuthOverlay'
import VerifyEmail from './VerifyEmailOverlay'
import SignUpOverlay from './SignUpOverlay'

import { fetchCampaigns } from "pointant/store/actions";
import FAQ from "./FAQ";

class Public extends Component{
  // constructor(props){
  //   super(props)
  // }
  componentDidMount(){
    const {fetchCampaigns} = this.props
    fetchCampaigns()
  }
  render(){
    const { campaign_uuid_paths } = this.props;
    return (
      <div className="app">
        {
        //<AuthOverlay/>
      }
        <SignUpOverlay/>
        <VerifyEmail/>
        <Header />
        <div className="h-100 d-flex flex-column">
          <div className="h-100 flex-grow-1">
            <Route exact path="/" component={PointantJumbo} />
            <Switch>
              <Route exact path="/" component={LandingPage} />
              <Route
                exact
                path={campaign_uuid_paths}
                component={CampaignPage}
              />
              <Route exact path='/howitworks' component={FAQ}/>
              <Route component={NotFoundPage} />
            </Switch>
          </div>
          <Footer />
        </div>
      </div>
    );

  }
}

const mapStateToProps = (state, ownProps) => {
  const campaign_uuid_paths = `/:campaign_uuid(${getCampaignUUIDs(state).join("|")})`;
  return {
    ...ownProps,
    campaign_uuid_paths
  };
};
const mapDispatch = (dispatch)=>{
  
  return {
    fetchCampaigns: ()=>dispatch(fetchCampaigns())
  }
}

const PublicContainer = connect(mapStateToProps,mapDispatch)(Public);

export default PublicContainer;
