import React from 'react';
import App from './App';
import {BrowserRouter as Router} from 'react-router-dom';
import {Provider} from 'react-redux'
import PropTypes from 'prop-types'
import ScrollToTop from './ScrollToTop'

const isStaging = process.env.REACT_APP_TARGET==="staging"
const devBasePath = {}
if(isStaging){
  devBasePath.basename="/pointant/client-web-app"
}

const Root = ({store})=>(
    <Provider store={store}>
        <Router {...devBasePath}>
          <ScrollToTop>
            <App />
          </ScrollToTop>
        </Router>
    </Provider>
)

Root.propTypes = {
  store: PropTypes.object.isRequired
}

export default Root