import React from "react";

import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
import DualLang from "./DualLang";
import { setLanguage } from "pointant/store/actions";
import { connect } from "react-redux";
import {rev_texts as text, texts as languages } from "pointant/languages";
import useLanguage from "useLanguage";

const LanguageSelect = props => {
  const { label, languageID, onSelectLanguage } = props;

  return (
    <DropdownItem
      onClick={e => {
        onSelectLanguage(languageID);
      }}
    >
      {label}
    </DropdownItem>
  );
};

const LanguageSelector = props => {
  const {onSelectLanguage }= props
  const {switchLanguage} = useLanguage({language: text.id,setLanguage: onSelectLanguage})
  const Ls = Object.keys(languages).map(language_id => {
    return (
      <Lseletor
        key={language_id}
        languageID={language_id}
        label={languages[language_id].long}
        onSelectLanguage={switchLanguage}
      />
    );
  });
  return (
    <UncontrolledDropdown nav inNavbar>
      <DropdownToggle nav caret>
        <DualLang text={text.long} />
      </DropdownToggle>
      <DropdownMenu right style={{'top':'55px'}}>
        {Ls}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

const mapStateToProps = (state, props) => ({ ...props });

const mapDispatchToProps = dispatch => {
  return {
    onSelectLanguage: languageID => dispatch(setLanguage(languageID))
  };
};

const Lseletor = connect(
  mapStateToProps,
  mapDispatchToProps
)(LanguageSelect);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LanguageSelector)
