import React, { Component, Fragment, useState } from 'react'
import { FormInput } from './CustomFormInputs'
import {
  Form,
  Button,
  Row,
  Col,
  // UncontrolledCollapse,
  // Collapse,
  // Card,
  // CardBody,
  Label,
  // ButtonGroup,
  FormGroup,
  Input,
  // FormFeedback,
} from 'reactstrap'
import { connect } from 'react-redux'
// import { getCampaign } from 'pointant/adapter/campaign_info'

import PeriodDisplay from './PeriodDisplay'
// import register_for_event from 'pointant/store/actions/eventregistration'
import {
  GoogleSignInButton,
  FacebookSignInButton,
  PointantSignInForm,
} from './SignInElements';
import { sanitiseMLT } from 'pointant/utils'
import {rev_texts} from 'pointant/languages'
import DualLang from './DualLang';
export class CampaignHeader extends Component {
  constructor(props) {
    super(props)
    this.state = {
      expanded: false
    }
    this.toggle = this.toggle.bind(this);
  }
  toggle() {
    this.setState(state => ({ expanded: !state.expanded }));
  }

  render() {

    //Show banner image, name, time and location
    const { campaign = {} } = this.props
    const img = campaign.imageURL
    const name = campaign.name.en
    const location = campaign.location.en
    const time = campaign.opening_hours.en
    const details = campaign.description.en
    const registrationDetails = campaign.registrationDetails && campaign.registrationDetails.en
    const truncateLength = 100
    return (
      <div >
        <img alt={campaign.name.en} className='img-fluid mb-1' src={img} />
        <h5 className='mb-0'>{name}</h5>
        <p className='mb-0'><small>
          <b>

            {"Event Period: "}
          </b>

          <PeriodDisplay period={campaign.period} />
        </small></p>
        <p className='mb-0'><small>
          <b>

            {"Opening Hours: "}
          </b>
          {time}
        </small>
        </p>
        <p className='mb-0'><small>
          <b>
            {"Location: "}
          </b>
          {location}

        </small>
        </p>
        <small
          style={{
            "whiteSpace": "pre-wrap"
          }}
        >
          <p className=' mb-0'>
            <b>

              {"Details: "}
            </b>
          </p>
          <span
          >
            {details.substring(0, truncateLength)}
          </span>
          {
            !this.state.expanded &&
            <span>
              ...
          </span>
          }
          {
            !this.state.expanded &&
            <p className='text-center mb-1' onClick={this.toggle}>
              <DualLang text={rev_texts.more_details}/>
          </p>
          }
          {
            this.state.expanded &&
            <Fragment>
              <span>
                {details.substring(truncateLength)}
              </span>
              <br />
              <span className='text-muted'>

                {registrationDetails}
              </span>
            </Fragment>
          }
          {
            this.state.expanded &&
            <p className='text-center mb-1' onClick={this.toggle}>
            <DualLang text={rev_texts.less_details}/>
          </p>
          }

        </small>
      </div>
    )
  }
}
//two views, input and confirm
//input view needs [field setup, value]


export const InputView = props => {
  const { formFieldData, data, className, onChange, onSubmit, language } = props
  return (
    <Form onSubmit={e => {
      e.preventDefault()
      onSubmit()
    }} autoComplete='on' className={className}>
      <Label className='form-em-text'>
      
      <DualLang text={rev_texts.registration_fill_fields}/>
      </Label>
      {
        formFieldData.map(detail => {
          let label = sanitiseMLT(detail.label, language)
          let placeholder = sanitiseMLT(detail.placeholder, language)
          return (
            <FormInput
              {...detail}
              label={label}
              placeholder={placeholder}
              key={detail.id}
              value={data[detail.id]}
              language={language}
              onChange={value => onChange(detail.id, value)}
            />
          )
        })
      }
      <div className='d-flex'>
        <Button color='primary' className='ml-auto'>
          <DualLang text={rev_texts.continue}/>
        </Button>

      </div>

    </Form>
  )
}
export const ConfirmView = props => {
  const { formFieldData, data, className, onSubmit, onBack, language } = props
  const [agree, setAgree] = useState(false)
  return (
    <Form onSubmit={(e) => {
      e.preventDefault()
      onSubmit()
    }} className={className}>
      <Label className='form-em-text'>
        <DualLang text={rev_texts.registration_confirm_data}/>
      </Label>
      {
        formFieldData.map(detail => {
          // console.log({ detail })
          let value = data[detail.id]
          if (detail.options) {
            const possibleOptions = detail.options.filter(option => option.value === value)
            if (possibleOptions.length > 0) {
              const option = possibleOptions[0]
              value = sanitiseMLT(option.label, language)
            }
          }
          let label = sanitiseMLT(detail.label, language)
          return (
            <Row key={detail.id} className='my-2'>
              <Col xs={6}>{label}</Col>
              <Col>{value}</Col>
            </Row>
          )
        })
      }


      <FormGroup check className='text-center mt-1'>
        <Label check>
          <Input
            type="checkbox"
            value={agree}
            onChange={e => setAgree(!agree)}
            required
          />{" "}
        <DualLang text={rev_texts.registration_information_agreement}/>
              </Label>
      </FormGroup>
      <div className='mt-5 d-flex flex-row justify-content-end'>

        <Button className='' type='button' onClick={(e) => {
          e.preventDefault()
          onBack()
        }}>
        <DualLang text={rev_texts.back}/>
        </Button>
        <Button color='primary' className='ml-1' type='submit'
        >
        <DualLang text={rev_texts.submit}/>
        </Button>
      </div>

    </Form>
  )
}

class FormContainerComponent extends Component {
  constructor(props) {
    super(props);
    //Receive the setup information from props
    const { registrationFormFieldData = [],
      //  cid,
       } = props
    // console.log(props)
    this.state = this.setDefaultState(registrationFormFieldData)
  }
  setDefaultState = (formData) => {
    let state = {
      data: {},
      showConfirmScreen: false,
    }
    formData.forEach(detail => {
      state.data[detail.field_id] = detail.default || ''
    })
    // console.log({ msg: "Set default state", state, })
    return state
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.registrationFormFieldData !== this.props.registrationFormFieldData) {
      // console.log({ msg: "HTIS SHOULD OCCUR AFTER FORM DATA GET", data: this.props.registrationFormFieldData })
      this.setState(this.setDefaultState(this.props.registrationFormFieldData))
    }
  }
  onBack = () => {
    this.setState({ showConfirmScreen: false })
  }
  onSubmit = () => {
    const { data } = this.state
    const { registerForEvent, 
      // scrollOnSubmit 
    } = this.props
    registerForEvent({ data })
  }
  onContinue = () => {
    const {
      //  registerForEvent,
        scrollOnContinue } = this.props
    this.setState({ showConfirmScreen: true })
    if (scrollOnContinue === 'top') {
      window.scrollTo(0, 0);
    }
  }
  onChange = (id, value) => {
    this.setState((prevState, prevProps) => {
      let data = prevState.data
      data[id] = value
      return { data }
    })
  }
  render() {
    const { registrationFormFieldData = [], 
      // registrationDetails,
       language } = this.props
    const { data, showConfirmScreen, } = this.state
    // console.log({ registrationDetails, registrationFormFieldData })
    return (
      <Fragment>
        <InputView
          formFieldData={registrationFormFieldData}
          data={data}
          className={`m-1${showConfirmScreen ? ' d-none' : ''}`}
          onChange={this.onChange}
          onSubmit={this.onContinue}
          language={language}
        />
        <ConfirmView
          formFieldData={registrationFormFieldData}
          data={data}
          className={`m-1${!showConfirmScreen ? ' d-none' : ''}`}
          onBack={this.onBack}
          onSubmit={this.onSubmit}
          language={language}
        />
      </Fragment>
    )
  }
}
export const FormContainer = connect((state, props) => ({ ...props, language: state.ui.language }))(FormContainerComponent)
// export const FormStateDecider = ({})=>{
//   if (!user) {
//     // console.warn('User not logged in')
//     content = <UserLoginMessage />
//   } else if (registrations.isFetching) {
//     // console.warn('Getting registration information for user')
//     content = <CheckingIfAlreadyRegistered />
//   } else if (registrations.entities) {
//     // console.warn('User is already registered for the even')
//     content = <AlreadyRegistered />
//   } else if (this.props.formMeta.isFetching) {
//     // console.warn('Loading form data')
//     content = <LoadingFormData />
//   } else if (result.isFetching) {
//     // console.warn('Currently Registering user')
//     content = <CurrentlyRegistering />
//   } else if (result.entities) {
//     // console.warn('Submitting Registration Data')
//     // console.log({ result: result.entities })
//     const successState = result.entities.result
//     if (successState === "success") {
//       // console.warn('Successfully Registered? <Insert check for no quota here?>')
//       content = <SuccessfullyRegistered />
//     } else if (successState === 'fail') {
//       // console.warn('Failed to register')
//       content = <RegistrationFailed />
//     } else {
//       // console.log({ msg: "this shouldn't occur", successState })
//       content = <RegistrationFailed />
//     }
//   } else {
//     // console.warn('Showing form')
//     content = <FormContainer 
//       registrationDetails={registrationDetails}
//       registrationFormFieldData={formFieldData}
//       cid={campaign.id}
//       registerForEvent={(params) => {
//         registerForEvent({ ...params, cid: campaign.id, uid: user.uid })
//       }} />
//   }
// }
export const AlreadyRegistered = props => {
  return <div>
    <Label className='form-em-text'>
      <DualLang text={rev_texts.registration_already_registered}/>
    </Label>
  </div>;
};
export const LoadingFormData = props => {
  return <div>
    <Label className='form-em-text'>
      <DualLang text={rev_texts.registration_loading_form}/>
    </Label>
  </div>;
};
export const SuccessfullyRegistered = props => {
  return <div>
    <Label className='form-em-text'>
      
<DualLang text={rev_texts.registration_success}/>
  </Label>
  </div>;
};
export const RegistrationFailed = props => {
  return <div>
    <Label className='form-em-text'>
<DualLang text={rev_texts.registration_failed}/>
  </Label>
  </div>;
};
export const CurrentlyRegistering = props => {
  return <div>
    <Label className='form-em-text'>
<DualLang text={rev_texts.registration_currently_registering}/>
    </Label>
  </div>;
};

export const CheckingIfAlreadyRegistered = props => {
  return <div>
    <Label className='form-em-text'>
<DualLang text={rev_texts.registration_checking_status}/>
    </Label>
  </div>;
};
export const UserLoginMessage = props => {
  return <div>
    <Label className='form-em-text'>
<DualLang text={rev_texts.registration_login_to_register}/>
    </Label>
  </div>;
};
export const RegistrationQuotaFilledMessage = props => {
  const {message} = props
  return (
    <div>
      <Label className='form-em-text'>
<DualLang text={message?message:rev_texts.registration_quota_filled}/>
      </Label>
    </div>
  );
};
export const UserLoginFeature = props => {
  return (
    <div className='text-center d-flex flex-column align-items-center'>
      <div className='text-center'><h5>
        
<DualLang text={rev_texts.registration_login_to_register}/>
        </h5></div>
      <div style={{ maxWidth: '200px' }}>

        <PointantSignInForm />
        <div className='flex-row d-flex w-100' >
          <div className=' flex-grow-1'>
            <hr />
          </div>
          <div className='mx-2' style={{ lineHeight: '33px' }}>
            <span className='text-muted'>
              Or
          </span>
          </div>
          <div className=' flex-grow-1'>
            <hr />
          </div>
        </div>
        <GoogleSignInButton />
        <FacebookSignInButton />
      </div>
    </div>
  )
}