import React from "react";
import {
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardImg,
  // CardText,
  // Badge,
} from "reactstrap";
import { Link } from "react-router-dom";
import DualLang from "./DualLang";
import PeriodDisplay from './PeriodDisplay'
// import DualDate from "./DualDate";
import { rev_texts as text } from "pointant/languages";

const AspectRatioImage = props=>{
  const {image} = props
  return (
    <div className="w-100 fixed-aspect fixed-aspect-2-1">
      <CardImg className="border-bottom" top src={image} />
    </div>
  )
}

const CampaignCardContents = props => {
  const { campaign } = props;
  return (
    <Card className="h-100">
      <AspectRatioImage image={campaign.imageURL}/>
      <CardBody className="text-left flex-grow-1">
        <CardSubtitle className="text-muted mb-1">
          <PeriodDisplay period={campaign.period}/>
        </CardSubtitle>
        <CardTitle className="text-dark">
          <DualLang text={campaign.name}/>
        </CardTitle>
      </CardBody>
      <CardBody className="mb-0 d-flex justify-content-end">
        {campaign.formURL &&
        <CardSubtitle className="mb-1 bg-primary text-white p-1 rounded">
          <DualLang text={text.event_registration_required}/>
        </CardSubtitle>
        }
      </CardBody>
    </Card>
  );
};
const CampaignCardComposed = props => {
  const { campaign } = props;
  return (
    <Link
      className={'custom-card'}
      to={`/${campaign.uuid}`}
    >
      <CampaignCardContents campaign={campaign} />
    </Link>
  );
};


export default CampaignCardComposed;
