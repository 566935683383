import React, { Fragment } from "react";
import moment from "moment";
import { Container } from "reactstrap";
import { connect } from "react-redux";
import DownloadBadgeSection from './DownloadAppSection'
import ContactUsSection from './EnquirySection'
import CampaignSection from './CampaignListSection'

const LandingPage = props => {
  const { present, past, future } = props;
  return (
    <Container>
      <div className='my-1 my-sm-5'>
        <DownloadBadgeSection/>
      </div>
      <hr/>
      {
        (present.length+past.length+future.length)>0 &&
        (
        <Fragment>
        <div className='my-1 my-sm-5'>
        <CampaignSection past={past} present={present} future={future}/>
        </div>
        <hr/>
        </Fragment>
        )
      }
      <div className='my-1 my-sm-5'>
      <ContactUsSection/>
      </div>
    </Container>
  );
};

const mapStateToProps = (state, ownProps) => {
  const campaigns = state.campaigns.entities;
  // console.log({msg:JSON.stringify(campaigns)})

  let present = [];
  let past = [];
  let future = [];
  const today = moment();
  // // console.log({campaigns})
  Object.entries(campaigns).forEach(([key, campaign]) => {
    if (today.isBefore(campaign.period.start,'day')) {
      future.push(campaign);
    } else if (today.isAfter(campaign.period.end,'day')) {
      past.push(campaign);
    } else {
      present.push(campaign);
    }
  });
  // future = future
  // present = present
  // past = past
  return {
    ...ownProps,
    future,
    present,
    past,
    campaigns: state.campaigns.entities
  };
};

const LandingPageContainerContainer = connect(mapStateToProps)(LandingPage);

export default LandingPageContainerContainer;
