
import {combineReducers} from 'redux'
import ui from './ui'
import campaigns from './campaigns'
import auth from './auth'
import eventregistration from './eventregistration'
import registrationresult from './registrationResult'
import registrationform from './registrationform'

const rootReducer = combineReducers({
  ui,
  campaigns,
  auth,
  eventregistration,
  registrationresult,
  registrationform,
})

export default rootReducer