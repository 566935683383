import React,{useState} from 'react'
// import { AboutUsImage1, AboutUsImage2 } from 'pointant/assets'
// import { rev_texts as text } from "pointant/languages";
// import { Container } from 'reactstrap'
// import DualLang from './DualLang'
import { TitleFAQ, TitleStampInactive, TitleStampActive, TitleRegisterActive, TitleRegisterInactive, Q1Que, Q1Ans, Q2Que, Q2Ans } from 'pointant/assets/faq/index';



const ButtonImage = props=>{
  const [isHovering,setHovering] = useState(false)
  const {normalImage, hoverImage, scrollTarget,alt} = props
  return (
    
    <button 
      className='faq-button' 
      onClick={e=>window.scrollTo({left:0,top:scrollTarget.current.offsetTop,behavior:'smooth'})}
      onMouseOver={e=>setHovering(true)}
      onMouseOut={e=>setHovering(false)}
      >
      <img src={isHovering?hoverImage:normalImage}
        alt={alt}
      />
    </button>
  )
}
const Spacer = props=>(
  <div style={{height: props.height}} />
)
const Question = props=>{
  const {Q, A} = props
  return (
    <div style={{width: '100%'}}>
      <img alt={Q.alt} src={Q.img}/>
      <div style={{borderTop: '1px solid black'}}/>
      <Spacer height='50px'/>
      <div className='text-center' >
      <img alt={A.alt} src={A.img}/>

      </div>
    </div>
  )
}


const FAQ = props => {
  let ref1 = React.createRef()
  let ref2 = React.createRef()
  let q1 = <div ref={ref1}><Question Q={Q1Que} A={Q1Ans}/></div>
  let q2 = <div ref={ref2}><Question Q={Q2Que} A={Q2Ans}/></div>
  return (
      <div className="mb-3 faq-page" 
        >
        <Spacer height={'55px'}/>
        <img src={TitleFAQ} alt={"Frequently Asked Questions"}/>
        <Spacer height={'55px'}/>
        <div className='d-flex flex-row justify-content-center'>
          <ButtonImage
            normalImage={TitleRegisterInactive}
            hoverImage={TitleRegisterActive}
            scrollTarget={ref1}
            alt={"How to register for an event"}
            />
          <div style={{width: '35px'}}/>
          <ButtonImage
            normalImage={TitleStampInactive}
            hoverImage={TitleStampActive}
            scrollTarget={ref2}
            alt={"How to collect stamps"}
          />
        </div>
        <Spacer height='65px'/>
          {q1}
          <Spacer height='55px'/>
          {q2}
          <Spacer height='65px'/>
      </div>

  )
}

export default FAQ