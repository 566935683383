export const sanitiseMLT = (text,language='en',defaultText='',defaultLanguage='en')=>{
  let result = defaultText
  // // console.log({field})
  if(text===undefined || text === null){
    // // console.warn({msg:'field equals undefined or null',field})
  }else if(typeof text==='string'){
    // // console.log({msg:'field equals string',field})
    result = text
  }else if (text[language]){
    // // console.log({msg:'field equals language object thatt exists',field})
    result = text[language]
  }else if (text[defaultLanguage]){
    // // console.log({msg:'field equals language object thatt exists - en',field})
    result = text[defaultLanguage]
  }else{
    // // console.warn({msg:'label equals sometthting weird',field})
  }
  return result
}