import React,{Fragment} from 'react'
import DualLang from "./DualLang";
import CampaignCardUI from "./CampaignCard";
import { Row, Col } from "reactstrap";
import {rev_texts as text} from "pointant/languages";

const CampaignCategoryView = props => {
  const { campaigns, dltitle } = props;
  if (!campaigns || campaigns.length<1) {
    return null;
  }
  const campaign_components = campaigns.map(campaign => {
    return (
      <Col key={campaign.uuid} md="6" lg='4' className="mb-sm-3 mb-1 neat-height">
        <CampaignCardUI campaign={campaign} />
      </Col>
    );
  });
  return (
    <div className="mt-5">
      <div className="mb-3">
        <h2 className="">
          <DualLang text={dltitle} />
        </h2>
      </div>
      <Row className="">{campaign_components}</Row>
    </div>
  );
};

const CampaignSection = props => {
  const { present, past, future } = props;
  return (
    <Fragment>
      <CampaignCategoryView
        dltitle={text.landing_campaigns_present}
        campaigns={present}
      />
      <CampaignCategoryView
        dltitle={text.landing_campaigns_future}
        campaigns={future}
      />
      <CampaignCategoryView
        dltitle={text.landing_campaigns_past}
        campaigns={past}
      />
    </Fragment>
  );
};

export default CampaignSection