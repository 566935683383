import React, { 
  // Component,
   Fragment } from "react";
import {
  Col,
  FormGroup,
  Label,
  Input,
  UncontrolledTooltip,
  FormFeedback
} from "reactstrap";
import {sanitiseMLT} from 'pointant/utils'

const FormInputLabel = props => {
  const { label, details, id, 
    // type,
    required } = props;
  return (
    <div className='d-flex justify-content-between'>
      <Label>
        {required?<span style={{color: 'red'}}>*</span>:null}{label}
      </Label>
      <Label className=''>
        {details &&
          (
            <Fragment>
              <i className="material-icons" id={`info-icon-${id}`}>info</i>
              <UncontrolledTooltip placement="right" target={`info-icon-${id}`}>
                {details}
              </UncontrolledTooltip>
            </Fragment>
          )
        }
      </Label>
    </div>
  );
}
const FormInputTextNumber = props => {
  const {title,required, disabled,value, onChange, placeholder, type,autoComplete,name,invalid,pattern } = props;
  return (
    <Input
      type={type}
      placeholder={placeholder}
      value={value}
      onChange={e => onChange(e.target.value)}
      required={required}
      autoComplete={autoComplete}
      name={name}
      disabled={disabled}
      invalid={invalid && invalid!==""}
      pattern={pattern}
      title={title}
    />
  );
}

const FormInput = props => {
  const { title, pattern, required, value, onChange, label,disabled, placeholder, type, details, id, split = 2,autoComplete,name, error } = props;
  //invalid if: error is a text of any > "" or if error === true
  //invalid if error
  const invalid = !(error===null || error===undefined || error==='')
  return (
    <FormGroup row disabled={disabled}>
      <Col md={split}>
        <FormInputLabel
          label={label}
          details={details}
          id={id}
          required={required}
        />
      </Col>
      <Col md={12 - split}>
        <FormInputTextNumber
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          type={type}
          autoComplete={autoComplete}
          name={name}
          disabled={disabled}
          invalid={invalid}
          required={required}
          pattern={pattern}
          title={title}
        />
        <FormFeedback valid={!invalid} >{error}</FormFeedback>
      </Col>
    </FormGroup>
  );
};


const FormInputRadio = props => {
  const {
    // language, 
    value, onChange, disabled,
    // placeholder, type, 
    checked, 
    // id, 
    label, required } = props;
  // console.log(label)
  return (

    <FormGroup check disabled={disabled}>
      <Label check>
        <Input 
          type="radio" 
          name={"radio" + value} 
          checked={checked} 
          onChange={onChange} 
          disabled={disabled} 
          required={required}
          />{label}
      </Label>
    </FormGroup>
  );
}
const FormRadio = props => {
  const { value, onChange, label,disabled, 
    // placeholder, type, 
    details, id, options, split = 2, required } = props;
  return (
    <FormGroup row disabled={disabled} >
      <Col md={split}>
        <FormInputLabel
          label={label}
          details={details}
          id={id}
          required={required}
        />
      </Col>
      <Col md={12 - split}>
        {
          options.map(option => {
            return (
              <FormInputRadio
                key={option.value}
                label={option.label}
                checked={value === option.value}
                onChange={e => onChange(option.value)}
                disabled={disabled}
                required={required}
              />
            )
          })
        }
      </Col>
    </FormGroup>
  );
};
const FormSelect = props => {
  const { language, value, onChange,disabled, label, placeholder, type, details, id, options, split = 2 ,required} = props;
  return (
    <FormGroup row disabled={disabled}>
      <Col md={split}>
        <FormInputLabel
          label={label}
          details={details}
          id={id}
          required={required}
        />
      </Col>
      <Col md={12 - split}>

        <Input
          type={type}
          placeholder={placeholder}
          value={value}
          onChange={e => onChange(e.target.value)}
          required={required}
          disabled={disabled}
        >
          {
            options.map(option => {
              return (
              <option
                key={option.value}
                value={option.value}
              >
                {sanitiseMLT(option.label,language)}
              </option>
              )
            })
          }
        </Input>
      </Col>
    </FormGroup>
  );
};
const FormCheck = props=>{
  const { value, onChange, label, placeholder, disabled,
    // type, 
    details, id, split = 2,required } = props;
  return (
    <FormGroup row>
      <Col md={split}>
        <FormInputLabel
          label={label}
          details={details}
          id={id}
          required={required}
        />
      </Col>
      <Col md={12 - split}>
        <FormInputTextNumber
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          type={'checkbox'}
          disabled={disabled}
          required={required}
        />
      </Col>
    </FormGroup>
  );
}

const FormInputSwitch = props => {
  const { type,language,options } = props
  let sanOptions = options
  if(options){
    //go through each
    sanOptions = options.map(option=>{
      return {
        ...option,
        label: sanitiseMLT(option.label,language)
      }
    })
  }
  let sanProps = {
    ...props,
    label: sanitiseMLT(props.label,language),
    placeholder: sanitiseMLT(props.placeholder,language),
    details: sanitiseMLT(props.details,language),
    options: sanOptions,
  }
  switch (type) {
    case 'select':
      return <FormSelect {...sanProps} />
    case 'radio':
      return <FormRadio {...sanProps} />
    case 'check':
      return <FormCheck {...sanProps}/>
    case 'text':
    case 'number':
    case 'tel':
    case 'email':
    default:
      return <FormInput {...sanProps} />
  }
}

export {
  FormInputSwitch as FormInput,
};
