import {REGISTER_FOR_EVENT as ACTION_TYPE} from '../actionTypes'

const {PENDING,FULFILLED,REJECTED} = ACTION_TYPE

function event_registration(state = {
  isFetching: false,
  entities: null,
  firstFetch: true
},action){
  switch (action.type) {
    case PENDING:
      return {
        ...state,
        isFetching: true,
        error: '',
        firstFetch: false
      }
    case FULFILLED:
      return {
        ...state,
        isFetching: false,
        lastUpdated: action.payload.time,
        entities: action.payload.data
      }
    case REJECTED:
      return {
        ...state,
        isFetching: false,
        error: action.payload
      }
    default:
      return state
  }
}

export default event_registration