import React, {
  // Component,
  Fragment,
  useCallback,
  useState,
  useEffect
} from "react";
// import { FormInput } from "./CustomFormInputs";
import { withRouter } from "react-router-dom";
import {
  // Form,
  Button,
  // Dropdown,
  // DropdownToggle,
  // DropdownItem,
  // DropdownMenu,
  // UncontrolledDropdown,
  // Label,
  // Input,
  // FormGroup,
  Collapse,
  ModalBody,
  Modal,
  ModalHeader,
  ModalFooter
} from "reactstrap";
import {
  userSignOut,
  // userSignUp,
  // hideAuthUI,
  sendVerifyEmail,
  reloadUser
} from "pointant/store/actions/authentication";
import {
  // connect,
  useSelector,
  useDispatch
} from "react-redux";
import {
  // text,
  rev_texts
} from "pointant/languages";
import DualLang from "./DualLang";

//Show modal when something triggers in app that requires auth (everything aside from browse)
//

const FixedAuthOverlay = props => {
  const {
    className = "",
    // user, //:reduxUser,
    // verified,
    // signOut,
    // verifyEmail,
    // verifyEmailSent,
    // pathname,
    // redirected,
    // profile,
    // reloadTheUser
    location: { pathname = "" } = {}
  } = props;

  // const {pathname=""} = location
  const verifyEmailSent = useSelector(state => state.auth.verifyEmailSent);
  const profile = useSelector(state => state.auth.profileData);
  const redirected = useSelector(state => state.auth.fromRedirectedAuth);
  const user = useSelector(state => state.auth.user);
  const dispatch = useDispatch();
  const signOut = useCallback(() => dispatch(userSignOut()), [dispatch]);
  const verifyEmail = useCallback(
    pathname => dispatch(sendVerifyEmail(pathname)),
    [dispatch]
  );
  const reloadTheUser = useCallback(() => dispatch(reloadUser()), [dispatch]);

  // const [user,setUser] = useState(null)
  // show the overlay if user is not null (firebase authenticated), but email is not verified
  const isEmailVerified = user && user.emailVerified;
  const isUserSignedIn = user !== null && user !== undefined;
  let isUserFromFacebook = false;
  if (isUserSignedIn) {
    if (user.providerData) {
      if (Array.isArray(user.providerData)) {
        let facebookProviders = user.providerData.filter(
          data => data.providerId === "facebook.com"
        );
        if (facebookProviders.length > 0) {
          isUserFromFacebook = true;
        }
      }
    }
  }
  // console.log({ user, isUserSignedIn, isEmailVerified, isUserFromFacebook });
  //   const isUserFromFacebook = isUserSignedIn && user.additionalUserInfo && user.additionalUserInfo.providerId === "facebook.com"
  const showOverlay = isUserSignedIn && !isEmailVerified && !isUserFromFacebook;
  let userEmail = "";
  let hasEmailAddress = false;
  if (isUserSignedIn) {
    hasEmailAddress = typeof userEmail === "string" && userEmail !== "";
    if (hasEmailAddress) {
      userEmail = user.email;
    }
  }

  // const show = user!==null && !verified && !this.state.verified;
  // const [refreshTimer, setRefreshTimer] = useState(null);
  // const [emailSent, setEmailSent] = useState(false);
  const emailSent = verifyEmailSent;
  const verifyUserEmail = useCallback(() => {
    if (isUserSignedIn && !isEmailVerified) {
      // setEmailSent(true)
      verifyEmail(pathname);
    }
  }, [verifyEmail, pathname, isEmailVerified, isUserSignedIn]);

  const handleVerifyEmailClick = useCallback(
    e => {
      e.preventDefault();
      verifyUserEmail();
    },
    [verifyUserEmail]
  );

  const handleSignOutClick = useCallback(
    e => {
      e.preventDefault();
      signOut();
    },
    [signOut]
  );

  useEffect(() => {
    if (!emailSent && redirected && profile) {
      if (profile.additionalUserInfo && profile.additionalUserInfo.isNewUser && !isUserFromFacebook) {
        verifyUserEmail();
      }
    }
  }, [emailSent, verifyUserEmail, redirected, profile,isUserFromFacebook]);

  // const [timer,setTimer] = useState(null)
  // const [reloadDebounce,setReloadDebounce] = useState(true)
  const [canReload, setCanReload] = useState(1); // 1 => waiting, -1 => running
  useEffect(() => {
    // if user reload is necessary, call it, and disable reload for 5 seconds
    // console.log("entering use effect");
    if (
      isUserSignedIn &&
      !isEmailVerified &&
      !isUserFromFacebook &&
      canReload === 1
    ) {
      // console.log("Reloading");
      reloadTheUser();
      // user.reload()
      setCanReload(-1);
      // setReloadDebounce(false)
      // console.log("Waiting 10 seconds");

      //     // const timeout = setTimeout(()=>{
      //     //   setReloadDebounce(true)
      //     //   console.log("Can reload again")
      //     // },10000)
      //     // // setTimer(timeout)
      //     // return ()=>{
      //     //   console.log("CLearing timer")
      //     //   clearTimeout(timeout)
      //     // }
    }
  }, [
    canReload,
    isEmailVerified,
    isUserFromFacebook,
    isUserSignedIn,
    reloadTheUser
  ]);
  useEffect(() => {
    if (canReload === -1) {
      // console.log("Starting timer");
      setCanReload(0);
      // const timeout = 
      setTimeout(() => {
        // setReloadDebounce(true)
        setCanReload(1);
        // console.log("Can reload again");
      }, 10000);
      // setTimer(timeout)
      // return ()=>{
      //   console.log("CLearing timer")
      //   clearTimeout(timeout)
      // }
    }
  }, [canReload]);

  // const [requestUserReload, setRequestUserReload] = useState(false);
  // const [intervalTimer, setIntervalTimer] = useState(null);
  // useEffect(() => {
  //   console.log("Creating Interval");
  //   const interval = setInterval(() => {
  //     setRequestUserReload(true);
  //     console.log("Requesting user reload");
  //   }, 10000);
  //   setIntervalTimer(interval);
  //   return () => {
  //     console.log("Clearing interval");
  //     clearInterval(interval);
  //   };
  // }, []);
  // useEffect(() => {
  //   if (requestUserReload) {
  //     setRequestUserReload(false);
  //     // do i actually need to reload?
  //     console.log("User reload was requested");
  //     if (isUserSignedIn && !isEmailVerified && !isUserFromFacebook) {
  //       console.log("Reloading User");
  //       reloadTheUser();
  //       // user.reload();
  //     } else {
  //       console.log("User reload was denied");
  //       // clearInterval(intervalTimer)
  //     }
  //   }
  // }, [
  //   requestUserReload,
  //   user,
  //   isUserSignedIn,
  //   isEmailVerified,
  //   isUserFromFacebook,
  //   intervalTimer,
  //   reloadTheUser
  // ]);

  return (
    <Modal isOpen={showOverlay} className={className}>
      <ModalHeader>
        <DualLang text={rev_texts.auth_verify_email_address} />
      </ModalHeader>
      <ModalBody>
        <p>
          <DualLang text={rev_texts.auth_click_link_in_email_to_verify} />
        </p>
        <Collapse isOpen={emailSent}>
          <p>
            {isUserSignedIn && hasEmailAddress ? (
              <Fragment>
                <DualLang text={rev_texts.auth_verification_email_sent} />
                {userEmail}
              </Fragment>
            ) : (
              <DualLang text={rev_texts.auth_verification_sent} />
            )}
          </p>
        </Collapse>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={handleVerifyEmailClick}>
          <DualLang
            text={
              emailSent
                ? rev_texts.auth_send_verification
                : rev_texts.auth_resend_verification
            }
          />
        </Button>{" "}
        <Button color="secondary" onClick={handleSignOutClick}>
          <DualLang text={rev_texts.auth_sign_out} />
        </Button>
      </ModalFooter>
    </Modal>
  );
};

// const mapState = (state, props) => {
//   // console.log({ state });
//   let pathname = "";
//   if (props && props.location) {
//     pathname = props.location.pathname;
//   }
//   // console.log(state.auth)
//   // console.log(`Does user exist: ${state.auth.user}`)
//   // console.log(`Is their email verified: ${state.auth.user && state.auth.user.emailVerified}`)
//   return {
//     pathname: pathname,
//     // user: state.auth.user,
//     // verified: state.auth.user && state.auth.user.emailVerified,
//     // verifyEmailSent: state.auth.verifyEmailSent,
//     // redirected: state.auth.fromRedirectedAuth,
//     // profile: state.auth.profileData
//   };
// };
// const mapDispatch = (dispatch, getState) => {
//   return {
//     // signOut: () => dispatch(userSignOut()),
//     // verifyEmail: pathname => dispatch(sendVerifyEmail(pathname)),
//     // reloadTheUser: () => dispatch(reloadUser())
//   };
// };

export default withRouter(
  FixedAuthOverlay
  // connect(
  //   mapState,
  //   mapDispatch
  // )(FixedAuthOverlay)
);
