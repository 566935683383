import axios from "axios";
import { REST_API_URL } from "./constants";

const API_SUB_URL = "/users";


const process = data => {
  // console.log(data);
  return data.data;
};

const post_user_data = ({ auth, userData, type, uid, cid, params, data } = {}) => {
 
  let headers = {};
  if (auth) {
    headers.authorization = auth;
  }
  const url = `${REST_API_URL}${API_SUB_URL}/${uid}`;

  return axios
    .post(url, { ...userData  }, { headers, params })
    .then(process);
};
const patch_user_data = ({ auth, userData, type, uid, cid, params, data } = {}) => {
 
  let headers = {};
  if (auth) {
    headers.authorization = auth;
  }
  const url = `${REST_API_URL}${API_SUB_URL}/${uid}`;

  return axios
    .patch(url, { ...userData  }, { headers, params })
    .then(process);
};

const get_user_data = ({ token: auth, uid, cid } = {}) => {
  let headers = {};
  if (auth) {
    headers.authorization = auth;
  }
  const url = `${REST_API_URL}${API_SUB_URL}/${uid}/registrations`;
  const params = {};
  // console.log({auth,uid,cid})
  return axios.get(url, { headers, params }).then(({ data }) => {
    const { code, message } = data;
    if (code && message) {
      // console.error({ code, message });
      return null;
    }
    const { registrations, result } = data;
    if(result!=="success"){
      // console.error({message: "API/user/registrations function successfully executed, but encountered an error. See firebase // console for more details"})
      return null
    }
    // console.log({ data, registrations });
    const regList = registrations.filter(dataItem => {
      return dataItem.campaign_id === cid;
    });
    // console.log({regList})
    if (regList.length > 0) {
      return regList[0];
    } else {
      return null;
    }
  });
};

export default post_user_data;

export { post_user_data as post, get_user_data as get , patch_user_data as patch};
