import textIDs from './textid'
import EN from './en'
import ZH from './zh'

const texts = {
  'zh': ZH,
  'en': EN,
}

let rev_texts = {}
for(let textID of textIDs){
  let dynamicTextObject = {}
  Object.entries(texts).forEach(([languageID,languageText])=>{
    dynamicTextObject[languageID] = languageText[textID]
  })
  rev_texts[textID] = dynamicTextObject
}

// export default text
export {texts,rev_texts}