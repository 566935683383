import {CHANGE_AUTH,SIGN_OUT,SEND_VERIFICATION_EMAIL, REDIRECTED_PAGE_LOAD, SET_USER_PROFILE_DATA, RELOAD_USER} from '../actionTypes'

function auth(state = {
  initialised: false,
  user: null,
  verifyEmailSent: false,
  fromRedirectedAuth: false,
  profileData: null
}, action){
  switch(action.type){
    case SEND_VERIFICATION_EMAIL.FULFILLED:
      return {
        ...state,
        verifyEmailSent: true
      }
    case REDIRECTED_PAGE_LOAD:
      return {
        ...state,
        fromRedirectedAuth: true
      }
    case RELOAD_USER.FULFILLED:
      return {
        ...state,
        user: action
        // fromRedirectedAuth: true
      }
    case SET_USER_PROFILE_DATA:
      return {
        ...state,
        profileData: action.profileData
      }
    case CHANGE_AUTH:
      return {
        ...state,
        user: action.user,
        initialised: true
      }
    case SIGN_OUT:
      return {
        ...state,
        user: null,
        initialised: true
      }
    default:
      return state
  }
}
export default auth