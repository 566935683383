import React,{Component} from 'react'
import {Container} from 'reactstrap'

class PageNotFound extends Component{
    constructor(props){
        super(props)
        this.state={
            show: false
        }
    }
    componentDidMount(){
        this.timer = setTimeout(()=>this.setState({show:true}),1000)
    }
    componentWillUnmount(){
        clearTimeout(this.timer)
    }
    
    render(){
        if(!this.state.show){return null}
        return (
            <Container className='py-5 text-center'>
                <div >
                    <h1>Page Not Found</h1>
                </div>
            </Container>
        )
    }
}

export default PageNotFound