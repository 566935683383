import {
  auth
} from "../../adapter";
import {
  CHANGE_AUTH,
  SIGN_OUT,
  USER_SIGN_OUT,
  USER_SIGN_IN,
  USER_SIGN_UP,
  SHOW_AUTH,
  HIDE_AUTH,
  USER_PASS_RESET,
  SEND_VERIFICATION_EMAIL,
  REDIRECTED_PAGE_LOAD,
  SET_USER_PROFILE_DATA,
  RELOAD_USER
} from "../actionTypes";
import * as user_data_functions from '../../adapter/user'
import { reload_user } from "pointant/adapter/authentication";

export const changeAuth = user => {
  if (user) {
    return {
      type: CHANGE_AUTH,
      user
    };
  } else {
    return {
      type: SIGN_OUT
    };
  }
};

export const receiveAuthRedirectResult = result=>{
  return {
    type: REDIRECTED_PAGE_LOAD
  }
}
export const setUserProfile = profileData=>{
  return {
    type: SET_USER_PROFILE_DATA,
    profileData
  }
}

const signOut = () => {
  return {
    type: USER_SIGN_OUT
  };
};
const signIn = () => {
  return {
    type: USER_SIGN_IN
  };
};
const signUp = () => {
  return {
    type: USER_SIGN_UP
  };
};
const passReset = () => {
  return {
    type: USER_PASS_RESET
  };
}

export const showAuthUI = () => {
  return {
    type: SHOW_AUTH
  }
}
export const hideAuthUI = () => {
  return {
    type: HIDE_AUTH
  }
}

export const userSignOut = () => {
  return (dispatch, getState) => {
    dispatch(signOut());
    auth.sign_out();
  };
};

export const sendVerifyEmail = (pathname) => {
  return {
    type: SEND_VERIFICATION_EMAIL.ACTION,
    payload: auth.send_verify_email({pathname})
  }
}

// const reloadUserFunction = 

export const reloadUser = ()=>{
  // const payload
  const user = reload_user()
  return {
    type: RELOAD_USER,
    payload: user
  }
}

export const userPasswordReset = (email) => {
  return (dispatch, getState) => {
    dispatch(passReset());
    auth.pass_reset(email);
  };
};

export const userSignUp = (params = {}) => {
  const {
    email,
    password,
    userData
  } = params;
  // console.log(params)
  return (dispatch, getState) => {
    dispatch(signUp())
    auth.sign_up(email, password).then(data => {
      //get token
      //const auth = idToken
      //call user post with data
      const uid = data.user.uid
      
      const authObject = data.user
      .getIdToken(true)
      .then(idToken => {
        // console.log({idToken})
        return user_data_functions.post({auth: idToken,userData: {...userData,email,platform: 'Web', channel: 'Email'},uid});
      }).then(data=>{
        // console.log({msg: 'user sign up result',data})
        return data
      }).then(data=>{
        dispatch(sendVerifyEmail())
        return data
      })
      // console.log({
      //   data,
      //   msg: "Data on Successful Signup"
      // })
      return authObject
    })
  }
}

export const userSignIn = (signInType = "email", params = {}) => {
  // console.log(params)
  switch (signInType) {
    case "google":
      return (dispatch, getState) => {
        // console.log("logging in with google");
        dispatch(signIn());
        auth.google_sign_in();
      };
    case "facebook":
      return (dispatch, getState) => {
        // console.log("logging in with facebook");
        dispatch(signIn());
        auth.facebook_sign_in();
      };
    case "email":
    default:
      return (dispatch, getState) => {
        const {
          email,
          password
        } = params;
        // console.log("logging in with email");
        dispatch(signIn());
        auth.sign_in(email, password);
      };
  }
};
// export const userSignIn = (user)=>{
//   return (dispatch,getState)=>{
//     dispatch(signIn())
//     auth.sign_in(user.email,user.password)
//   }
// }