import {connect} from 'react-redux'
import {  
  GoogleSignInElement,
  FacebookSignInElement,
  PointantSignInFormSmall ,
}  from './SignInElements'
import { 
  // userSignOut, 
  userSignIn, 
  // showAuthUI, 
  // userPasswordReset 
} from "pointant/store/actions/authentication";

// const mapState = (state,props)=>{
//   return {
    
//   }
// }

// const mapDispatch = dispatch => {
//   return {
//     signOut: () => dispatch(userSignOut()),
//     signIn: (signInType, params) => dispatch(userSignIn(signInType, params)),
//     signUp: () => dispatch(showAuthUI()),
//     passReset: (email) => dispatch(userPasswordReset(email)),
//   };
// };

const GoogleSignInButton = connect(null,dispatch=>({
  signInFunction: ()=>dispatch(userSignIn('google'))
}))(GoogleSignInElement)
const FacebookSignInButton = connect(null,dispatch=>({
  signInFunction: ()=>dispatch(userSignIn('facebook'))
}))(FacebookSignInElement)
const PointantSignInForm = connect(null,dispatch=>({
  signInFunction: ({email,password})=>
    dispatch(userSignIn('email',{email,password})
    )
}))(PointantSignInFormSmall)

export {
  GoogleSignInButton,
  FacebookSignInButton,
  PointantSignInForm,
}