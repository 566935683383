import thunkMiddleware from "redux-thunk";
import { createLogger } from "redux-logger";
import { createStore, applyMiddleware } from "redux";
import rootReducer from "./reducers";
import promise from "redux-promise-middleware";



const loggerMiddleware = createLogger();
// const promiseMiddleware = createPromiseMiddleware();

const middlewares = [
  thunkMiddleware,
  promise,
];

if (process.env.NODE_ENV === `development`) {
  middlewares.push(loggerMiddleware);
}


const store = createStore(
  rootReducer,
  applyMiddleware(...middlewares),
);

export default store;
