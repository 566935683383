import { createSelector } from "reselect";

const getCampaigns = state => state.campaigns.entities;


const getCampaignUUIDs = createSelector([getCampaigns], campaigns => {
  if (!campaigns) {
    return [];
  }
  const ids = Object.keys(campaigns);
  const list = ids.map(key => campaigns[key].uuid);
  return list;
});

export default getCampaignUUIDs