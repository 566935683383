import campaign_info from "pointant/adapter/campaign_info";
import { FETCH_CAMPAIGNS } from "../actionTypes";
import { fetchUIImages } from "./images";

const requestCampaigns = ()=>{
  return dispatch=>{
      return dispatch({
      type: FETCH_CAMPAIGNS.ACTION,
      payload: campaign_info().then(data=>({data,time:Date.now()}))
    })
  }
}

const shouldFetchCampaigns = state => {
  const campaigns = state.campaigns;
  if (campaigns.isFetching) {
    return false;
  }
  return true;
};

export const fetchCampaigns = () => {
  return (dispatch, getState) => {
    if (shouldFetchCampaigns(getState())) {
      return dispatch(requestCampaigns())
        .then(()=>{
          return dispatch(fetchUIImages())
        })
    }
  };
};
