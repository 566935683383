import React from "react";
import { Jumbotron, Container, } from "reactstrap";
import {rev_texts as text} from 'pointant/languages'
import DualLang from "./DualLang";

const Footer = props => (
  <Jumbotron className="border-top mb-0">
    <Container className="text-center">
      <div className="p-3">
        <p className="text-muted"><DualLang text={text.footer_copyright}/></p>
      </div>
    </Container>
  </Jumbotron>
);

export default Footer
