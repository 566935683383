import React from 'react';
import {Route,Switch,Redirect} from 'react-router-dom'
import StandAloneEventRegistration from './StandAloneRegistrationPage'
import EventRegistrationWebLinkPage from './EventRegistrationWebLink'
import DevOnlyStandAloneRegistrationRedirect from './DevOnlyStandAloneRegistrationRedirect';

const SpecialPages = props => {
  return (
    <Switch>
      {process.env.NODE_ENV==='development'&&
      <Route exact path='/m/' render={()=>{

        return <DevOnlyStandAloneRegistrationRedirect/>
      }}/>
    }
      <Route path='/m/campaign_registrations' component={StandAloneEventRegistration}/>
      <Route path='/s/campaign_registrations' component={EventRegistrationWebLinkPage}/>

      <Route render={()=><Redirect to='/'/>}/>
    </Switch>
  )
};

export default SpecialPages;
