// import firebase from 'firebase/app';
import 'firebase/storage';
import {CLOUD_STORAGE_URL} from './constants'
import firebase from './firebase'
// const CONFIG = {
//     apiKey: "AIzaSyCEnct8U5pmrcFvXA-kMm3LAPgw2TmaUb4",
//     authDomain: "pointant-admin.firebaseapp.com",
//     databaseURL: "https://pointant-admin.firebaseio.com",
//     projectId: "pointant-admin",
//     storageBucket: "pointant-admin.appspot.com",
//     messagingSenderId: "822465249061"
// }

// firebase.initializeApp(CONFIG);
const storage = firebase.app().storage(CLOUD_STORAGE_URL)

let storageRef = storage.ref()
const getImage = (refPath)=>{
  return storageRef.child(refPath).getDownloadURL()
}

export {
  getImage
}